import {useState, useRef} from 'react';
import {AnnotatedManufacturer, RenderedManufacturer} from "@/types/Manufacturer";
import {SelectedParameter} from "@/types/Parameters";
import {OmniclassTableInfo} from "@/types/OmniclassTableInfo";
import { getAssociatedManufacturers } from "@/api/services/omniclass";
import {filterManufacturers, getMultipleManufacturers} from "@/api/services/manufacturerService";
import {SortMethod} from "@/types/SortMethod";


/**
 * React hook to manage the list of manufacturers as selected parameter-values change.
 *
 * There are two ways that manufacturers can be added to the list of manufacturers:
 * - The list of manufacturers is retrieved from the backend. There is an initial list, and the list is updated when the
 *   selected parameter-values change.
 * - The list of manufacturers is extracted from the omniclass table parameters. This is used when the list of
 *   manufacturers is empty (ie: the omniclass is generated and has not yet been processed).
 *
 * @param {OmniclassTableInfo} tableInfo - Omniclass Table data
 */
export const useManufacturers = (tableInfo: OmniclassTableInfo) => {
  const [manufacturers, setManufacturers] = useState<RenderedManufacturer[] | null>(null);
  const manufacturersRef = useRef<RenderedManufacturer[] | null>(null);
  const initialManufacturers = useRef<AnnotatedManufacturer[] | null>(null);
  const [sortMethod, setSortMethod] = useState<SortMethod>('relevancy');
  const [expanded, setExpanded] = useState(false);
  const [isManufacturersFetched, setIsManufacturersFetched] = useState(false);

  // controls whether linear progress element is shown
  const [loading, setLoading] = useState(false);


  /**
   * Whether the parent component should render manufacturers as highlighted.
   *
   * This is used to delay rendering of the `ManufacturerList` rows as highlighted when the user selects a parameter-value.
   * The purpose is to prevent the UI from muting all rows before receiving a response from the backend.
   *
   * Usage is in `updateManufacturers` where the `shouldHighlight` state is set to `true` after the backend returns the
   * list of manufacturers to highlight, and set to `false` when the list of manufacturers is reset.
   */
  const [shouldHighlight, setShouldHighlight] = useState(false);

  /**
   * Extract manufacturer names from the omniclass table data parameters
   *
   * If there is a parameter name "Manufacturers" or "Brands", extract the manufacturer names from the parameter values.
   */
  const extractManufacturers = () => {
    const manufacturerParameter = tableInfo.parameters.find(
      parameter => parameter.name.toLowerCase().includes('manufacturer') || parameter.name.toLowerCase().includes('brand'));
    if (manufacturerParameter) {
      return manufacturerParameter.values.map(value => (value));
    }
    return [];
  }

  /**
   * Mark manufacturers as highlighted based on the titles given.
   *
   * This is used by `updateManufacturers` to highlight the manufacturers returned by the `filter_manufacturers` function.
   *
   * @param titles - The list of manufacturer titles to highlight
   */
  const setHighlighting = (titles: string[]) => {
    if (manufacturers) {
      const updatedManufacturers: RenderedManufacturer[] = manufacturers.map(manufacturer => {
        // Check if the manufacturer exists in the filtered manufacturers
        const existsInFiltered = titles.find(filteredManufacturerTitle => filteredManufacturerTitle === manufacturer.title);
        // If it does, set highlighted to true, otherwise set it to false
        return {...manufacturer, highlighted: !!existsInFiltered};
      });
      setManufacturersWrapper(updatedManufacturers);
    }
  }

  /**
   * Update manufacturers with the selected parameter-values.
   *
   * The backend will return a list of sorted/filtered manufacturer titles based on the selected parameter-values.
   * If all parameters are deselected, the list of manufacturers will be reset to the initial list.
   *
   * There is a 400ms delay added before setting the `manufacturers` state to null. This is to prevent the UI from
   * flickering (by showing the `SkeletonRows` component) when the user selects/deselects a parameter. This smooth
   * transition improves the UX and adds to the responsiveness of the UI.
   *
   * @param {Array} parameters - The selected parameters.
   */
  const updateManufacturers = (parameters: SelectedParameter[]) => {
    // create an `AbortController` instance
    const abortController = new AbortController();

    if (parameters.every(parameter => parameter.value === '')) {
      setManufacturersWrapper(initialManufacturers.current);

      // reset highlighting/muting state when the manufacturers are reset
      setShouldHighlight(false);
      return;
    }

    // if a response is not received within 400ms, the render a linear progress element
    let timeoutId: NodeJS.Timeout | null = setTimeout(() => {
      setLoading(true)
    }, 400);

    const filterManufacturersPromise = filterManufacturers(parameters,tableInfo.name, abortController.signal, expanded);

    filterManufacturersPromise.then(titles => {
      // clear the timeout if the response is received before the timeout
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      setHighlighting(titles);

      // show highlighting/muting when the manufacturers have been updated
      setShouldHighlight(true);

      // hide the linear progress  element
      setLoading(false);

      console.log('updated manufacturers');
    }).catch(error => {
      if (error.name === 'AbortError') {
        // if the request is aborted, do nothing
        return;
      } else {
        throw error;
      }
    });

    // return a cleanup function that aborts the fetch operation
    return () => abortController.abort();
  }

  /**
   * Order data based on the metadata fields.
   *
   * This is used by the `setManufacturersWrapper` function to order the manufacturers based on the `highlighted` and
   * `verified` fields. The precedence is as follows:
   * - Highlighted manufacturers take precedence over non-highlighted manufacturers
   * - Verified manufacturers take precedence over non-verified manufacturers
   * - Manufacturers with `links_for_professionals` take precedence over manufacturers without links
   *
   * The result is that highlighted manufacturers always are listed first. Verified manufacturers are listed first
   * within the two groups. Manufacturers with `links_for_professionals` are listed first within both the verified and
   * non-verified groups.
   *
   * @param data - The list of manufacturers
   */
  const orderManufacturersByRelevancy = (data: RenderedManufacturer[]): RenderedManufacturer[] => {
    return data.sort((a, b) => {
      const scoreA = (a.highlighted ? 4 : 0) + (a.verified ? 2 : 0) + (a.links_for_professionals ? 1 : 0);
      const scoreB = (b.highlighted ? 4 : 0) + (b.verified ? 2 : 0) + (b.links_for_professionals ? 1 : 0);
      return scoreB - scoreA;
    });
  }

  /**
   * Sort manufacturers alphabetically by title.
   *
   * @param data - The list of manufacturers
   */
  const sortAlphabetically = (data: RenderedManufacturer[]): RenderedManufacturer[] => {
    return [...data].sort((a, b) => a.title.localeCompare(b.title));
  }

  /**
   * Wrapper function which orders manufacturers before setting the `manufacturers` state.
   *
   * @param data - The list of manufacturers
   */
  const setManufacturersWrapper = (data: RenderedManufacturer[] | null) => {
    if (!data || data.length === 0) {
      setManufacturers(null);
      manufacturersRef.current = null;
      return;
    } else {
      const sortedData = sortMethod === 'relevancy' ? orderManufacturersByRelevancy(data) : sortAlphabetically(data);
      setManufacturers(sortedData);
      manufacturersRef.current = sortedData;
    }
  }

  /**
   * Manually add manufacturers to the list of manufacturers.
   *
   * This is used when the list of manufacturers is empty. The list of manufacturers is populated manufacturers extracted from
   * the tables parameter.
   */
  const bootstrapManufacturers = () => {
    const extracted = extractManufacturers();
    getMultipleManufacturers(extracted).then(data => {
      if (data.length === 0) {
        setManufacturers([]);
        return false;
      }

      // add the manufacturers that are returned
      let tmp = data;

      // for any manufacturer that is not found, add it to the initial manufacturers list
      const missingManufacturers = extracted.filter(manufacturer => !data.find(d => d.title === manufacturer));
      missingManufacturers.forEach(title => tmp.push({title: title, verified: false}));
      setManufacturersWrapper(tmp);
      initialManufacturers.current = tmp;
    })
  }

  /**
   * Change the sorting method and resort the manufacturers.
   *
   * @param method - The new sorting method ('relevance' or 'alphabetically')
   */
  const changeSortMethod = (method: SortMethod) => {
    setSortMethod(method);
    if (manufacturers) {
      const sortedData = method === 'relevancy' ? orderManufacturersByRelevancy(manufacturers) : sortAlphabetically(manufacturers);
      setManufacturers(sortedData);
    }
  }

  /**
   * Populate the list of manufacturers with unfiltered (not filtered by the parameter-selection) manufacturers.
   *
   * If the returned list of manufacturers is empty, the list of manufacturers is populated with manufacturers extracted
   * from the table parameters by using `bootstrapManufacturers`.
   */
  const fetchManufacturers = async () => {
    setLoading(true);
    try {
      const data = await getAssociatedManufacturers(tableInfo.name);
      if (data.length === 0) {
        bootstrapManufacturers();
      } else {
        initialManufacturers.current = data;
        if (manufacturersRef.current === null || manufacturersRef.current.length === 0) {
          setManufacturersWrapper(data);
          setIsManufacturersFetched(true); // Mark that manufacturers have been fetched
        } else {
          console.log('manufacturers already fetched. Initial manufacturers.');
        }
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * Refresh the manufacturers when the expanded state changes.
   *
   * This takes into account any selected parameters.
   *
   * This will never be called for a generated
   * omniclass, so bootstrapping of the manufacturers is not necessary.
   *
   * @param parameters
   */
  const refreshManufacturers = async (parameters: SelectedParameter[]) => {
    // if a response is not received within 400ms, the render a linear progress element
    let timeoutId: NodeJS.Timeout | null = setTimeout(() => {
      setLoading(true)
    }, 400);

    // update the actual expanded list of manufacturers
    await getAssociatedManufacturers(tableInfo.name, expanded)
      .then(data => {
        initialManufacturers.current = data
      })

    updateManufacturers(parameters);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setLoading(false);
  }

  const clearManufacturers = () => {
    setManufacturersWrapper(null);
    initialManufacturers.current = null;
  }

  const restoreManufacturers = (initial: AnnotatedManufacturer[], rendered: RenderedManufacturer[]) => {
    initialManufacturers.current = initial;
    setManufacturersWrapper(rendered);
    setIsManufacturersFetched(true);
    setShouldHighlight(true);
  }

  return { manufacturers, loading, expanded, setExpanded, fetchManufacturers, updateManufacturers, refreshManufacturers, clearManufacturers, shouldHighlight, changeSortMethod,
    isManufacturersFetched, manufacturersRef, restoreManufacturers
  };
};