import {
  NavigationMenu, NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList, NavigationMenuTrigger, navigationMenuTriggerStyle
} from '@/components/ui/navigation-menu';
import { Link, useLocation } from 'react-router-dom';
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import React from "react";
import {cn} from "@/lib/utils";

const BasicMenuItem = ({ to, children }: {to: string, children?: any}) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  return (
    <NavigationMenuItem>
      <NavigationMenuLink active={isActive} className={navigationMenuTriggerStyle()} asChild>
        <Link to={to}>
        {children}
        </Link>
      </NavigationMenuLink>
    </NavigationMenuItem>
  )
}

export const Menu = () => {
  return (
    <div className={"flex m-8"}>
      <NavigationMenu>
        <NavigationMenuList>

          <BasicMenuItem to="/">
            <ChevronLeftIcon className={"w-4 h-4 mr-2"} />
            Back to Scout
          </BasicMenuItem>

          <NavigationMenuItem>
            <NavigationMenuTrigger>Omniclass</NavigationMenuTrigger>

            <NavigationMenuContent>
              <ul className={"grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]"}>

                <ListItem href="/admin/omniclass/unreviewed" title="Unreviewed">
                  View and delete the unreviewed omniclass tables.
                </ListItem>

                <ListItem href="/admin/omniclass/upload" title="Upload">
                  Upload generated omniclass tables.
                </ListItem>

                <ListItem href="/admin/omniclass/missing_manufacturers" title="Missing Manufacturers">
                  Find omniclass tables missing manufacturers.
                </ListItem>

                <ListItem href="/admin/omniclass/search" title={"Search"}>
                  Search for an omniclass table.
                </ListItem>

              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>

          <NavigationMenuItem>
            <NavigationMenuTrigger>Manufacturer</NavigationMenuTrigger>

            <NavigationMenuContent>
              <ul className={"grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]"}>

                <ListItem href="/admin/manufacturer/upload" title="Upload">
                  Upload generated manufacturer tables.
                </ListItem>

                <ListItem href="/admin/manufacturer/search" title="Search">
                  Search existing manufacturers.
                </ListItem>

                <ListItem href="/admin/manufacturer/create" title="Create">
                  Create a new manufacturer.
                </ListItem>

                <ListItem href="/admin/manufacturer/utilities/all" title="Utilities">
                  Various manufacturer utilities
                </ListItem>

              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>

          <NavigationMenuItem>
            <NavigationMenuTrigger>Users</NavigationMenuTrigger>

            <NavigationMenuContent>
              <ul className={"grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]"}>

                <ListItem href="/admin/users/invite" title="Invites">
                  Invite and manage guest users.
                </ListItem>
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>

          <BasicMenuItem to={"/admin/status"}>Database Stats</BasicMenuItem>

          <BasicMenuItem to={"/admin/log"}>DB Log</BasicMenuItem>

        </NavigationMenuList>
      </NavigationMenu>
    </div>
)
}

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {

  const isActive = useLocation().pathname === props.href;

  return (
    <li>
      <NavigationMenuLink active={isActive} asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  )
})
ListItem.displayName = "ListItem"