import React from "react";
import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import {AnnotatedManufacturer} from "@/types/Manufacturer";


interface DetailBreadcrumbProps extends React.ComponentPropsWithoutRef<"nav"> {
  manufacturer: AnnotatedManufacturer;
  omniclass: string | null;
}

export const DetailBreadcrumb = ({manufacturer, omniclass, className}: DetailBreadcrumbProps) => {
  const backComponent = omniclass ?
    <BreadcrumbLink href={`/omniclass/${omniclass}`}>{omniclass}</BreadcrumbLink> :
    <BreadcrumbEllipsis className="h-4 w-4" />;
  return (
    <Breadcrumb className={className}>
      <BreadcrumbList>

        <BreadcrumbItem>
          <BreadcrumbLink href="/">Home</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbSeparator />

        <BreadcrumbItem>
          {backComponent}
        </BreadcrumbItem>

        <BreadcrumbSeparator />

        <BreadcrumbItem>
          <BreadcrumbPage>{manufacturer.title}</BreadcrumbPage>
        </BreadcrumbItem>

      </BreadcrumbList>
    </Breadcrumb>
  )
}