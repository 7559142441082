import React, {useEffect, useState} from "react";
import {useLoaderData, useNavigate} from "react-router-dom";

import {useComponentSize} from "@/app/hooks/useComponentSize";

import { Header } from "@/app/components/header";
import {ParameterSection} from "@/app/components/ParameterSection";
import {ValueSection} from "@/app/components/ValueSection";
import {OmniclassValue} from "@/app/components/OmniclassValue";
import {UIButton} from "@/app/components/button";

import {ManufacturerSpecificVariant} from "@/types/OmniclassTableInfo";
import {useSelectedParameters} from "@/app/hooks/useSelectedParameters";
import {AnnotatedManufacturer} from "@/types/Manufacturer";
import {getManufacturerData} from "@/api/services/manufacturerService";
import {ManufacturerPanel} from "@/app/pages/ViewManufacturerVariant/components/ManufacturerPanel";
import {useSaveConfig} from "@/app/hooks/useSaveConfig";

/**
 * Main application component
 */
export const ViewManufacturerVariant = () => {
  const info = useLoaderData() as ManufacturerSpecificVariant;

  const navigate = useNavigate();

  const { selectedValues, handleSelectValue, setSelectedValues } = useSelectedParameters(info.parameters);

  // this state is controlled by the `ParameterSection`, then used by the `ValueSection`
  const [selectedParameterName, setSelectedParameterName] = useState<string | undefined>(undefined);

  // the `ValueSection` represents the selected parameter represented by `selectedParameterName`
  const selectedParameter = info.parameters.find(parameter => parameter.name === selectedParameterName);

  const [maxHeightRef, { height }] = useComponentSize();

  const [manufacturerInfo, setManufacturerInfo] = useState<AnnotatedManufacturer | null>(null);

  useSaveConfig(true, info, selectedValues, setSelectedValues);

  useEffect(() => {
    getManufacturerData(info.manufacturer_title).then(setManufacturerInfo);
  }, [info.manufacturer_title]);

  return (
    <div className={"min-h-[100vh] flex flex-col justify-center"}>

      <Header />

      {/* Info & Controls */}
      <div className={"flex flex-row items-start p-4 gap-2"}>
        <div className={"flex-grow flex flex-row items-center"}>
          <OmniclassValue omniclass={info.name} />
        </div>

        <UIButton onClick={() => navigate("/")}>
          Start New Search
        </UIButton>
      </div>

      {/* Main table content */}
      <section className={"grid grid-cols-3 gap-4 p-8 bg-secondary flex-grow"}>
        <div ref={maxHeightRef} className={"max-h-fit"}>
          <ParameterSection
            parameters={info.parameters}
            selectedValues={selectedValues}
            selectParameter={setSelectedParameterName}
            selected={selectedParameterName}
            deselectValue={(parameter_name) => handleSelectValue(parameter_name, '')}
          />
        </div>
        <div style={{maxHeight: `${height}px`}}>
          <ValueSection
            parameter={selectedParameter}
            selectedValues={selectedValues}
            handleSelectValue={handleSelectValue}
          />
        </div>
        <div style={{maxHeight: `${height}px`}}>
          <ManufacturerPanel
            manufacturer={manufacturerInfo}
            selectedParameters={selectedValues}
            omniclassName={info.name}
          />
        </div>
      </section>

    </div>
  )
}