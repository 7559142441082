import {Parameter, SelectedParameter} from "@/types/Parameters";
import {
  highlightedColor,
  hoveredColor,
  selectedColor,
  SectionContainer,
  SectionHeader,
  SectionItem
} from "./Section";
import {Badge} from "@/components/ui/badge";
import {XMarkIcon} from "@heroicons/react/24/outline";


interface ParameterItemProps {
  parameter: Parameter;
  selectParameter: (parameter_name: string) => void;
  selected?: string;
  selectedValues?: SelectedParameter[];
  deselectValue: (parameter_name: string) => void;
  highlightedValues: string[];
}

export const ParameterItem = ({parameter, selectParameter, selected, selectedValues, deselectValue, highlightedValues}: ParameterItemProps) => {
  const active = selected === parameter.name;
  const highlighted = highlightedValues.includes(parameter.name)
  const parameterValue = selectedValues?.find(({name}) => name === parameter.name)

  const bg_class = active ?
    selectedColor : highlighted ?
      highlightedColor : hoveredColor;

  return (
    <SectionItem onClick={() => selectParameter(parameter.name)}
        className={bg_class}>
      {parameter.name}
      {parameterValue && parameterValue.value !== '' &&
          <Badge className={"text-xs ml-2 border-yellow-500 inline-flex items-center hover:shadow-md cursor-default"} variant={"outline"}>
            {parameterValue.value}
            <XMarkIcon className={"h-4 w-4 ml-1 cursor-pointer text-yellow-600 hover:text-red-700 hover:stroke-2"}
                       onClick={() => deselectValue(parameter.name)}
                       data-testid={`deselect-${parameter.name}-value`}
            />
          </Badge>}
    </SectionItem>
  )
}


interface ParameterSectionProps {
  parameters: Parameter[];
  selectParameter: (parameter_name: string) => void;
  selectedValues: SelectedParameter[];
  deselectValue: (parameter_name: string) => void;
  selected?: string;
}

export const ParameterSection = ({parameters, selectParameter, selectedValues, deselectValue, selected}: ParameterSectionProps) => {
  const filteredParameters = parameters.filter(parameter => !parameter.name.toLowerCase().includes('manufacturer') && !parameter.name.toLowerCase().includes('brand'));

  const highlightedValues = selectedValues.filter(({value}) => {
    return value !== ''
  }).map(({name}) => name)

  return (
    <SectionContainer>
      <SectionHeader>Step 1: Select Parameters</SectionHeader>
      <ul className={"flex flex-col"}>
        {filteredParameters.map((parameter, index) => (
          <ParameterItem
            key={index}
            parameter={parameter}
            selectParameter={selectParameter}
            selected={selected}
            selectedValues={selectedValues}
            deselectValue={deselectValue}
            highlightedValues={highlightedValues}
          />
        ))}
      </ul>
    </SectionContainer>
  )
}