import {MutableRefObject, useEffect} from "react";
import {ManufacturerSpecificVariant, OmniclassTableInfo} from "@/types/OmniclassTableInfo";
import {SelectedParameter} from "@/types/Parameters";
import {AnnotatedManufacturer, RenderedManufacturer} from "@/types/Manufacturer";

const LOCAL_STORAGE_KEY = 'omniclassConfiguration';

export const useSaveConfig = (
  isMounted: boolean,
  tableInfo: OmniclassTableInfo | ManufacturerSpecificVariant,
  selectedValues: SelectedParameter[],
  setSelectedValues: (selectedValues: SelectedParameter[]) => void,
  selectedManufacturers?: string[],
  setSelectedManufacturers?: (selectedManufacturers: string[]) => void,
  expanded?: boolean,
  setExpanded?: (expanded: boolean) => void,
  manufacturers?: RenderedManufacturer[] | null,
  restoreManufacturers?: (initial: AnnotatedManufacturer[], rendered: RenderedManufacturer[]) => void
) => {
  /**
   * Load or initialize selectedValues and selectedManufacturers from localStorage
   */
  useEffect(() => {
    const storedInfo = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedInfo) {
      const {
        name,
        selectedValues: storedSelectedValues,
        selectedManufacturers: storedSelectedManufacturers,
        expanded: storedExpandedValue,
        manufacturers: storedManufacturers,
      } = JSON.parse(storedInfo);
      if (name === tableInfo.name) {
        setSelectedValues(storedSelectedValues);

        // Only set selected manufacturers if the function is provided
        if (setSelectedManufacturers) setSelectedManufacturers(storedSelectedManufacturers);

        // Only set expanded if the function is provided
        if (setExpanded) setExpanded(storedExpandedValue);

        // Only set initial manufacturers if the function is provided
        if (storedManufacturers.length > 0 && restoreManufacturers) {
          // remove the `highlighted` property from the stored manufacturers
          const initial: AnnotatedManufacturer[] = storedManufacturers.map((manufacturer: RenderedManufacturer) => {
            return {...manufacturer, highlighted: undefined}
          });

          restoreManufacturers(initial, storedManufacturers);
        }
      } else {
        // Reset if the omniclass table has changed
        localStorage.removeItem(LOCAL_STORAGE_KEY);
      }
    }
  }, []);

  /**
   * Save configuration when upon changes
   *
   * Configuration is saved to localStorage and includes:
   * - name: the name of the omniclass table
   * - manufacturerTitle: the title of the manufacturer specific variant (applicable when viewing manufacturer specific variants)
   * - selectedValues: the selected parameters
   * - selectedManufacturers: the selected manufacturers (applicable to generic omniclasses)
   * - expanded: the expanded state of the manufacturer list (applicable to generic omniclasses)
   * - initialManufacturers: the initial manufacturers (applicable to generic omniclasses)
   *
   * This is restricted to only run after the component is mounted.
   */
  useEffect(() => {
    if (isMounted) {
      // remove logo from manufacturers
      let cleanedManufacturers = manufacturers?.map(manufacturer => {
        return {...manufacturer, logo: undefined};
      });

      const userInputConfig = {
        name: tableInfo.name,
        manufacturerTitle: (tableInfo as ManufacturerSpecificVariant).manufacturer_title || '',
        selectedValues,
        selectedManufacturers: selectedManufacturers || [],
        expanded: expanded || false,
        manufacturers: cleanedManufacturers || [],
      };
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userInputConfig));
    }
  }, [selectedValues, selectedManufacturers, isMounted, expanded, manufacturers]);
}