import React from "react";
import {cn} from "@/lib/utils";
import { Button } from "@/components/ui/Button";


export const UIButton = ({children, className, ...props}: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <Button {...props} className={cn(
      "rounded-3xl bg-primary hover:bg-sky-700 text-white px-4 md:px-8",
      className
    )}>
      {children}
    </Button>
  )
}