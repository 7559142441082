import {Button} from "@/components/ui/Button";
import {ArrowDownTrayIcon} from "@heroicons/react/24/outline";
import {
  AlertDialog, AlertDialogAction, AlertDialogCancel,
  AlertDialogContent, AlertDialogDescription, AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from "@/components/ui/alert-dialog";
import {XMarkIcon} from "@heroicons/react/16/solid";
import {downloadOmniclassCsv} from "@/lib/csv";
import {deleteOmniclass, getOmniclass} from "@/api/services/omniclass";
import {Link} from "react-router-dom";


interface OmniclassItemProps {
  omniclassNumber?: string;
  omniclassName: string;
  onDeleteCallback?: (omniclassName: string) => void;
  disableDownload?: boolean;
  appendText?: string;
}

/**
 * Reusable component for displaying an omniclass item.
 *
 * This renders a `<li>` element with the omniclass name and two buttons:
 * - Download: Downloads the omniclass as a CSV file
 * - Delete: Deletes the omniclass from the database
 */
export const OmniclassItem = ({omniclassNumber, omniclassName, onDeleteCallback, disableDownload, appendText}: OmniclassItemProps) => {
  // If the number has been passed, display the full omniclass identifier to the user. Otherwise, just display the name.
  const identifier = omniclassNumber ? `${omniclassNumber} ${omniclassName}` : omniclassName;

  return (
    <li className={"flex flex-row justify-between items-center px-1 py-2 border-2 hover:bg-gray-200"}>
      <span className={"flex-grow ml-2"}>
        <Link to={`/admin/omniclass/view/${encodeURIComponent(omniclassName)}`} className={"text-blue-500 underline"}>
          {identifier}
        </Link>
      </span>
      <div>
        {!disableDownload && <DownloadButton omniclassName={omniclassName} appendText={appendText} />}
        <DeleteButtonDialog omniclassName={omniclassName} onDeleteCallback={onDeleteCallback}/>
      </div>
    </li>
  )
}

interface DownloadButtonProps {
  omniclassName: string;
  appendText?: string;
}

const DownloadButton = ({omniclassName, appendText}: DownloadButtonProps) => {
  // Callback for downloading an omniclass
  const downloadOmniclass = async () => {

    getOmniclass(omniclassName)
      .then(data => {
        // Ensure that the returned data is correct
        if (data.name && data.parameters) {
          // format omniclass name
          const omniclass_name = `${data.name} UNREVIEWED`;

          // download the csv
          downloadOmniclassCsv(omniclass_name, data.parameters, appendText);
        }
      }).catch(_ => {
      console.log("Unknown server error.")
    })
  };

  return (
    <Button variant={"ghost"} size={"icon"} onClick={downloadOmniclass}>
      <ArrowDownTrayIcon className={"h-4 w-4 text-green-500"}></ArrowDownTrayIcon>
    </Button>
  )
}

interface DeleteButtonDialogProps {
  omniclassName: string;
  onDeleteCallback?: (omniclassName: string) => void;
}

const DeleteButtonDialog = ({omniclassName, onDeleteCallback}: DeleteButtonDialogProps) => {
  // Callback to delete the omniclass. This will trigger the `onDeleteCallback` if it exists
  const handleDeleteOmniclass = async () => {
    deleteOmniclass(omniclassName)
      .then(_ => {
        if (onDeleteCallback) {
          onDeleteCallback(omniclassName);
        }
      }).catch(_ => {
        // TODO: show error as alert
        console.log("Unknown server error.")
      });
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant={"ghost"} size={"icon"}>
          <XMarkIcon className={"h-4 w-4 text-red-500"}></XMarkIcon>
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete the {omniclassName} omniclass
            from the database.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleDeleteOmniclass}>Delete</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}