import React, {useEffect, useRef, useState} from "react";

interface ComponentSize {
  width: number;
  height: number;
}

/**
 * React hook to get the size of a component.
 *
 * This is used in the `ResultsContainer` component to get the height of the `ResultTable` component,
 * that way the `ManufacturerList` component can be set to the same height.
 */
export function useComponentSize(): [React.RefObject<HTMLDivElement>, ComponentSize] {
  const ref = useRef<HTMLDivElement | null>(null);
  const [size, setSize] = useState({width: 0, height: 0});

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        setSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return [ref, size];
}