// noinspection SpellCheckingInspection

import {AnnotatedManufacturer} from "@/types/Manufacturer";
import {SectionTitle} from "./SectionTitle";


export const ContactEmailSection = ({ manufacturer }: { manufacturer: AnnotatedManufacturer }) => {
  return (
    <div className={"flex flex-col"}>
      <SectionTitle className={"text-lg"}>Contact Email</SectionTitle>
      <a href={`mailto:${manufacturer.email}`} className={"text-sky-600 hover:underline"} target={"_blank"} rel={"noreferrer"}>{manufacturer.email}</a>
    </div>
  )
}
