import {Omniclass} from "@/types/Omniclass";
import {Skeleton} from "@/components/ui/Skeleton";
import {OmniclassItem} from "@/admin/components/OmniclassItem";

interface OmniclassRowsProps {
  omniclasses: Omniclass[] | null;
  onDeleteCallback: (omniclassName: string) => void;
  appendText?: string;
  disableDownload?: boolean;
}

export const OmniclassRows = ({omniclasses, onDeleteCallback, disableDownload, appendText}: OmniclassRowsProps) => {
  let rows;
  if (omniclasses === null) {
    rows =
      Array.from({length: 20}, (_, __) => (
        <li key={__}>
          <Skeleton className={`rounded-md h-12`} />
        </li>
      ))
  }
  else {
    rows =
      omniclasses.length === 0 ? <li>No omniclasses</li> :
        omniclasses.map((omniclass, index) => (
          <OmniclassItem key={index}
                         omniclassNumber={omniclass.number}
                         omniclassName={omniclass.name}
                         onDeleteCallback={onDeleteCallback}
                         disableDownload={disableDownload}
                         appendText={appendText} />
        ));
  }

  return (
    <ul className={"grid grid-cols-1 md:grid-cols-2 gap-2"}>
      {rows}
    </ul>
  )
}
