import {
  AllAggregatorTags,
} from "src/app/components/AllAggregatorTags";
import { SectionTitle } from "./SectionTitle";
import {AggregatorContent as AggregatorContentRecord} from "@/types/Manufacturer";


/**
 * Shows a single row in the manufacturer list with the aggregator content sites that the manufacturer is on
 */
export const AggregatorContent = ({aggregatorContent}: { aggregatorContent: AggregatorContentRecord | null | undefined }) => {
  if (!aggregatorContent) {
    return null;
  }

  return (
    <div className={"flex flex-col"}>
      <SectionTitle className={"text-lg"}>Aggregator Content</SectionTitle>
      <div className={"flex flex-row gap-2"}>
        <AllAggregatorTags aggregatorContent={aggregatorContent} />
      </div>
    </div>
  )
}
