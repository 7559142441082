import {AnnotatedManufacturer} from "@/types/Manufacturer";
import {useEffect, useState} from "react";
import {getAssociatedOmniclasses} from "@/api/services/manufacturerService";

interface AssociatedOmniclassesProps {
  manufacturer: AnnotatedManufacturer;
}

export const AssociatedOmniclasses = ({manufacturer}: AssociatedOmniclassesProps) => {
  const [ associatedOmniclasses, setAssociationOmniclasses ] = useState<string[]>([]);

  // fetch associated omniclasses
  useEffect(() => {
    getAssociatedOmniclasses(manufacturer.title)
      .then(data => setAssociationOmniclasses(data))
  }, [manufacturer])

  return (
    <div>
      <h4 className={"text-lg mb-3"}>Products / Omniclasses</h4>
      <p className={"pb-4 text-gray-700/80 text-sm"}>
        The list below are some of the product types available from {manufacturer.title}:
      </p>
      <ul className={"grid grid-cols-3 gap-2"}>
        {associatedOmniclasses.map(omniclass => (<li className={"text-gray-500"} key={omniclass}>{omniclass}</li>))}
        {associatedOmniclasses.length === 0 && <li className={"text-gray-500"}>No associated omniclasses</li>}
      </ul>
    </div>
  )
}