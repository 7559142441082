import {CheckIcon} from "@heroicons/react/24/outline";
import { cva, type VariantProps } from "class-variance-authority"
import {cn} from "@/lib/utils";
import * as React from "react";

const tagVariants = cva(
  "rounded-md flex items-center gap-1 shadow-sm",
  {
    variants: {
      variant: {
        default:
          "bg-amber-400 text-white",
        inverse:
          "bg-amber-200 text-gray-700",
      },
      size: {
        default:
          "text-xs font-medium px-1.5 py-0.5",
        header:
          "text-sm font-semibold px-2 py-1",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

interface VerifiedTagProps
  extends React.BaseHTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof tagVariants> {}

export const VerifiedTag = ({variant, size, className}: VerifiedTagProps) => {
  return (
    <span className={cn(tagVariants({ variant, size, className }))}>
      <CheckIcon className={"h-3 w-3"} style={{strokeWidth: 4}}/>
      Verified
    </span>
  )
}