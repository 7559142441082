
import {AnnotatedManufacturer} from "@/types/Manufacturer";
import {SectionTitle} from "./SectionTitle";


export const HomepageSection = ({ manufacturer }: { manufacturer: AnnotatedManufacturer }) => {
  // noinspection SpellCheckingInspection
  return (
    <div className={"flex flex-col"}>
      <SectionTitle className={"text-lg"}>Homepage</SectionTitle>
      <a href={manufacturer.url} className={"text-sky-600 hover:underline"} target={"_blank"} rel={"noreferrer"}>{manufacturer.url}</a>
    </div>
  )
}