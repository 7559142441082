import {AnnotatedManufacturer} from "@/types/Manufacturer";


const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""


export const MapEmbed = ({manufacturer}: { manufacturer: AnnotatedManufacturer }) => (
  <div className={"h-full w-full"}>
    <iframe
      title={"Google Maps Embed"}
      className={"w-full h-full"}
      allowFullScreen
      referrerPolicy="no-referrer-when-downgrade"
      src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_API_KEY}&q=${manufacturer.formatted_address}&zoom=5`}>
    </iframe>
  </div>
)