import React, { lazy, Suspense } from "react";

import {createBrowserRouter} from "react-router-dom";
import {ErrorBoundary} from "@/components/ErrorBoundary";
import {MissingManufacturers} from "@/admin/pages/omniclass/MissingManufacturers";
import { getManufacturerData } from "@/api/services/manufacturerService";
import {
  getManufacturerOmniclassVariant,
  getManufacturerVariantForAdmin,
  getOmniclassForAdmin,
  getOmniclassTable
} from "@/api/services/omniclass";
import {decode_readable_url} from "@/lib/formatting";

import { AdvancedSearchPage } from "src/app/pages/AdvancedSearchPage";
import { ViewManufacturerVariant } from "@/app/pages/ViewManufacturerVariant";
import { StartPage } from "@/app/pages/StartPage";
import { ManufacturerDetails } from "@/app/pages/ManufacturerDetails";
import {ManufacturerSpecificVariant, OmniclassTableInfo} from "@/types/OmniclassTableInfo";

// auth pages
import {Login} from "@/auth/Login";
import {Logout, RequireToken} from "@/lib/auth";
import {getInvitedUsers} from "@/api/services/misc";

const InviteSignup = lazy(() => import("@/auth/InviteSignup"));

// static pages
const PrivacyPolicy = lazy(() => import("@/app/pages/PrivacyPolicy"));

// admin pages
const AdminHome = lazy(() => import("@/admin/pages/AdminHome"));
const Status = lazy(() => import("@/admin/pages/Status"));
const ScrapingLog = lazy(() => import("@/admin/pages/ScrapingLog"));
const UserInvites = lazy(() => import("@/admin/pages/users/invite"));

// omniclass pages
const SearchOmniclass = lazy(() => import("@/admin/pages/omniclass/SearchOmniclass"));
const ViewOmniclass = lazy(() => import("@/admin/pages/omniclass/ViewOmniclass"));
const ViewOmniclassVariant = lazy(() => import("@/admin/pages/omniclass/ViewOmniclassVariant"));
const CreateOmniclassVariant = lazy(() => import("@/admin/pages/omniclass/CreateOmniclassVariant"));
const EditOmniclass = lazy(() => import("@/admin/pages/omniclass/EditOmniclass"));
const EditOmniclassVariant = lazy(() => import("@/admin/pages/omniclass/EditOmniclassVariant"));
const Unreviewed = lazy(() => import("@/admin/pages/omniclass/Unreviewed"));
const OmniclassUpload = lazy(() => import("@/admin/pages/omniclass/OmniclassUpload"));

// manufacturer pages
const ManufacturerUpload = lazy(() => import("@/admin/pages/manufacturer/ManufacturerUpload"));
const ManufacturerCreate = lazy(() => import("@/admin/pages/manufacturer/ManufacturerCreate"));
const ViewManufacturer = lazy(() => import("@/admin/pages/manufacturer/ViewManufacturer"));
const SearchManufacturer = lazy(() => import("@/admin/pages/manufacturer/SearchManufacturer"));
const EditManufacturer = lazy(() => import("@/admin/pages/manufacturer/EditManufacturer"));
const ManufacturerUtilities = lazy(() => import("@/admin/pages/manufacturer/Utilities"));
const UploadManufacturerLogosUtility = lazy(() => import("@/admin/pages/manufacturer/Utilities/UploadManufacturerLogos"));

// loaders
const manufacturerLoader = async (title?: string) => {
  if (!title) {
    throw new Response("No title provided", { status: 429 });
  }
  const decoded = decode_readable_url(title);
  return await getManufacturerData(decoded);
}

const omniclassLoader = async (identifier?: string): Promise<OmniclassTableInfo> => {
  if (!identifier) {
    throw new Response("No identifier provided", { status: 429 });
  }
  const decoded = decode_readable_url(identifier);

  return await getOmniclassTable(decoded);
}

const manufacturerVariantLoader = async (identifier?: string, manufacturerTitle?: string): Promise<ManufacturerSpecificVariant> => {
  if (!identifier) {
    throw new Response("No identifier provided", { status: 429 });
  }
  else if (!manufacturerTitle) {
    throw new Response("No manufacturer title provided", { status: 429 });
  }

  const decoded = decode_readable_url(identifier);

  return getManufacturerOmniclassVariant(decoded, manufacturerTitle);
}

const adminOmniclassLoader = async (omniclassName?: string): Promise<OmniclassTableInfo> => {
  if (!omniclassName) {
    throw new Response("No identifier provided", { status: 429 });
  }
  const decoded = decode_readable_url(omniclassName);

  return await getOmniclassForAdmin(decoded);
}


const adminManufacturerVariantLoader = async (
  omniclassName?: string,
  manufacturerTitle?: string
): Promise<ManufacturerSpecificVariant> => {
  if (!omniclassName) {
    throw new Response("No identifier provided", { status: 429 });
  }
  else if (!manufacturerTitle) {
    throw new Response("No manufacturer title provided", { status: 429 });
  }

  const decoded = decode_readable_url(omniclassName);

  return getManufacturerVariantForAdmin(decoded, manufacturerTitle);
}

// global site routes
export const routes = createBrowserRouter([
  {
    path: "/",
    element: <RequireToken><StartPage /></RequireToken>,
    errorElement: <ErrorBoundary/>},
  {path: "privacy", element: <Suspense><PrivacyPolicy /></Suspense>},
  {
    path: "omniclass/:identifier",
    element: <RequireToken><AdvancedSearchPage /></RequireToken>,
    loader: ({params}) => omniclassLoader(params.identifier)
  },
  {
    path: "omniclass/:identifier/by/:manufacturerTitle",
    element: <RequireToken><ViewManufacturerVariant /></RequireToken>,
    loader: ({params}) => manufacturerVariantLoader(params.identifier, params.manufacturerTitle)
  },
  {
    path: "manufacturer/:title",
    element: <RequireToken><ManufacturerDetails /></RequireToken>,
    loader: ({params}) => manufacturerLoader(params.title)
  },

  // auth pages
  {
    path: "login",
    element: <Login />
  }, {
    path: "/logout",
    element: <RequireToken><Logout /></RequireToken>
  }, {
    path: "invite/signup",
    element: <RequireToken><Suspense><InviteSignup /></Suspense></RequireToken>
  },

  // admin pages
  {
    path: "admin",
    children: [
      {path: "", element: <RequireToken><Suspense><AdminHome /></Suspense></RequireToken>},
      {path: "status", element: <RequireToken><Suspense><Status /></Suspense></RequireToken>},
      {path: "log", element: <RequireToken><Suspense><ScrapingLog /></Suspense></RequireToken>},
      {
        path: "omniclass",
        children: [
          {
            path: "create",
            children: [
              {
                path: "variant",
                element: <RequireToken><Suspense><CreateOmniclassVariant /></Suspense></RequireToken>
              }
            ]
          },
          {
            path: "view",
            children: [
              {
                path: ":identifier",
                element: <RequireToken><Suspense><ViewOmniclass /></Suspense></RequireToken>,
                loader: ({params}) => adminOmniclassLoader(params.identifier),
              }, {
                path: ":identifier/by/:manufacturerTitle",
                element: <RequireToken><Suspense><ViewOmniclassVariant /></Suspense></RequireToken>,
                loader: ({params}) => adminManufacturerVariantLoader(params.identifier, params.manufacturerTitle)
              }
            ]
          },
          {
            path: "search",
            element: <RequireToken><Suspense><SearchOmniclass /></Suspense></RequireToken>,
          },
          {
            path: "edit",
            children: [
              {
                path: ":identifier",
                element: <RequireToken><Suspense><EditOmniclass /></Suspense></RequireToken>,
                loader: ({params}) => adminOmniclassLoader(params.identifier)
              },
              {
                path: ":identifier/by/:manufacturerTitle",
                element: <RequireToken><Suspense><EditOmniclassVariant /></Suspense></RequireToken>,
                loader: ({params}) => adminManufacturerVariantLoader(params.identifier, params.manufacturerTitle)
              }
            ]
          },
          {path: "unreviewed", element: <RequireToken><Suspense><Unreviewed /></Suspense></RequireToken>},
          {path: "upload", element: <RequireToken><Suspense><OmniclassUpload /></Suspense></RequireToken>},
          {path: "missing_manufacturers", element: <RequireToken><Suspense><MissingManufacturers /></Suspense></RequireToken>},
        ]
      }, {
        path: "manufacturer",
        children: [
          {path: "upload", element: <RequireToken><Suspense><ManufacturerUpload /></Suspense></RequireToken>},
          {path: "create", element: <RequireToken><Suspense><ManufacturerCreate /></Suspense></RequireToken>},
          {
            path: "view/:title",
            element: <RequireToken><Suspense><ViewManufacturer /></Suspense></RequireToken>,
            loader: ({params}) => manufacturerLoader(params.title)
          },
          {
            path: "search",
            element: <RequireToken><Suspense><SearchManufacturer /></Suspense></RequireToken>,
          },
          {
            path: "edit/:title",
            element: <RequireToken><Suspense><EditManufacturer /></Suspense></RequireToken>,
            loader: ({params}) => manufacturerLoader(params.title)
          },
          {
            path: "utilities",
            children: [
              {
                path: "all",
                element: <RequireToken><Suspense><ManufacturerUtilities /></Suspense></RequireToken>
              },
              {
                path: 'upload_logos',
                element: <RequireToken><Suspense><UploadManufacturerLogosUtility /></Suspense></RequireToken>
              }
            ]
          },
        ]
      },
      {
        path: "users",
        children: [
          {
            path: "invite",
            element: <RequireToken><Suspense><UserInvites /></Suspense></RequireToken>,
            loader: () => getInvitedUsers()
          },
        ]
      },
    ]
  },
]);