import {Skeleton} from "@/components/ui/Skeleton";

/** Create skeleton rows for loading state
 *
 * 20 rows should be sufficient
 */
export const SkeletonRows = () => {
  return (
    <ul className={'flex flex-col'} data-testid={'skeleton-rows'}>
      {Array.from({length: 20}, (_, __) => (
        <li key={__}>
          <Skeleton className={"rounded-md h-[1rem] my-2 mx-4"} />
        </li>
      ))}
    </ul>
  )
}