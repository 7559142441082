import {RenderedManufacturer} from "@/types/Manufacturer";
import {hoveredColor, SectionItem} from "@/app/components/Section";
import {
  AllAggregatorTags,
} from "@/app/components/AllAggregatorTags";
import {VerifiedTag} from "@/app/components/VerifiedTag";
import {useNavigate} from "react-router-dom";
import {manufacturerHasLinksForProfessionals} from "@/utils/checks";
import {Checkbox} from "@/components/ui/checkbox";
import {cn} from "@/lib/utils";

import { CubeIcon } from "@heroicons/react/24/outline";
import {overlayLinksWithAggregatorContent} from "@/utils/transforms";

/**
 * Component which indicates that the manufacturer has a value `links_for_professionals`
 *
 * This component is rendered in the `ManufacturerList` component.
 *
 * A 3D cube icon was chosen since the `links_for_professionals` should lead to CAD/BIM files, or other technical assets.
 */
const AssetLinkTag = () => (
  <span>
    <CubeIcon className={"h-5 w-5 text-red-500"} style={{strokeWidth: 2}}/>
  </span>
)

interface ManufacturerRowProps {
  manufacturer: RenderedManufacturer;
  shouldHighlight?: boolean;
  omniclass: string;
  selected: boolean;
  onSelect: () => void;
  disableSelection: boolean;
}

/**
 * Shows a single row in the manufacturer list with the manufacturer name and aggregator content
 *
 * If the manufacturer has a URL, the manufacturer name will be styled as a link. Otherwise, it will be plain text.
 *
 * Good omniclass examples to view this component:
 * - Strip Foundation Blocks
 * - Self Adhering Sheet Flexible Roof Flashings
 */
export const ManufacturerRow = ({manufacturer, shouldHighlight, omniclass, selected, onSelect, disableSelection}: ManufacturerRowProps) => {
  const navigate = useNavigate();

  const hasLinksForProfessional = manufacturerHasLinksForProfessionals(manufacturer);

  // overlay aggregator content with links_for_professionals
  const aggregatorContent = overlayLinksWithAggregatorContent(manufacturer);

  /**
   * Determine styling to use for the `SectionItem`
   *
   * If the parent component has highlighted values, then use highlighted/muted styling for manufacturers.
   */
  const itemStyle = (): string => {
    if (shouldHighlight) {
      if (manufacturer.highlighted) {
        // render section as highlighted
        return 'hover:bg-primary/20';
      }
      // render section as muted
      return 'hover:bg-primary/10';
    }
    // render section with default styling
    return hoveredColor;
  }

  /**
   * Determine styling to use for the manufacturer title
   *
   * If the parent component has highlighted values, then use highlighted/muted styling for the manufacturer title.
   */
  const titleStyle = (): string => {
    if (manufacturer.url) {
      if (shouldHighlight) {
        if (manufacturer.highlighted) {
          // render a link with highlighted styling
          return '';
        }
        // render a link with muted styling
        return 'text-primary/80 font-thin hover:underline';
      }
      // render a link with default styling
      return '';
    } else {
      // render plain text
      return ''
    }
  }

  const gotoManufacturer = () => {
    navigate(`/manufacturer/${manufacturer.title}`, {state: {omniclass}});
  }

  return (
    <SectionItem className={`text-left ${itemStyle()} first:mt-0`}>
      <div className={"flex flex-row items-center gap-1"}>

        <div className={"flex items-center flex-grow gap-2"}>
          <Checkbox checked={selected} onCheckedChange={onSelect} disabled={disableSelection && !selected} />
          <span className={cn("text-primary hover:underline", titleStyle())} onClick={manufacturer.url ? gotoManufacturer : () => {}}>
            {manufacturer.title}
          </span>
          {manufacturer?.verified && <VerifiedTag />}
          {hasLinksForProfessional && <AssetLinkTag />}
        </div>

        <AllAggregatorTags aggregatorContent={aggregatorContent} />
      </div>
    </SectionItem>
  )
}