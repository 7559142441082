import {createClient} from "@supabase/supabase-js";

const supabase_url = process.env.REACT_APP_SUPABASE_URL;
const supabase_key = process.env.REACT_APP_SUPABASE_KEY;

if (!supabase_url) {
  throw new Error("SUPABASE_URL is not defined");
}

if (!supabase_key) {
  throw new Error("SUPABASE_KEY is not defined");
}

export const SUPABASE = createClient(supabase_url, supabase_key);
