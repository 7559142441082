import React, { useState, useEffect } from 'react';
import './countdown.css';

export const GenerationCountdown = () => {
  const [countdown, setCountdown] = useState(60);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((currentCountdown) => currentCountdown - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full max-w-[50vw]">
      <p className="text-center text-gray-500">
        Please wait while this new omniclass/product is being generated.&nbsp;
      </p>
      <div className="flex items-center gap-2">
        <span className="countdown"/>
        <span className={"text-gray-400"}>({countdown}s)</span>
      </div>
    </div>
  );
}