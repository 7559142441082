import {ProductQuery} from "@/types/ProductQuery";

/**
 * Types of documents the user may request.
 */
export type FileRequest = 'Specifications' | 'Brochures' | 'CAD Drawings' | 'Technical Information' | 'BIM Models';

export const FileRequestValues: FileRequest[] = ['Specifications', 'Brochures', 'CAD Drawings', 'Technical Information', 'BIM Models'];

export type RFIRequest = {
  query: ProductQuery;
  manufacturer_titles: string[];
  email_content: string;
  request_id?: string;
  fileRequests: FileRequest[];
  user_name?: string;
  user_email: string;
}