import {AnnotatedManufacturer, Manufacturer} from "@/types/Manufacturer";
import {Link} from "react-router-dom";
import {Factory} from "lucide-react";
import {useEffect, useState} from "react";
import {getManufacturerOverview} from "@/api/services/manufacturerService";
import {LinearProgress} from "@/components/LinearProgress";
import {get_logo_url} from "@/utils/logos";


/**
 * Sort manufacturers based on the `verified_on` fiel
 *
 * The most recently verified manufacturers are shown first.
 *
 * @param manufacturers
 */
const sortManufacturers = (manufacturers: AnnotatedManufacturer[]) => {
  const sorted = manufacturers.sort((a, b) => {
    if (a.verified_on && !b.verified_on) {
      return -1;
    } else if (!a.verified_on && b.verified_on) {
      return 1;
    } else if (!a.verified_on && !b.verified_on) {
      return 0;
    } else if (a.verified_on && b.verified_on) {
      return b.verified_on.getTime() - a.verified_on.getTime();
    }
    return 0;
  });
  return sorted;
}

const ManufacturerCell = ({manufacturer, logo_url}: {manufacturer: Manufacturer, logo_url?: string | null}) => {
  return (
    <Link
      to={manufacturer.url ? manufacturer.url : ""}
      target={manufacturer.url ? "_blank" : ""}
    >
      <div key={manufacturer.title} className={"flex flex-col items-center justify-end gap-2 h-full p-4 rounded-2xl hover:bg-primary/10 hover:shadow-md"}>
        {(logo_url === undefined || logo_url === null) && <Factory className={"h-20 w-max stroke-1"} />}
        {logo_url && (
          <div className={"flex-grow flex items-center"}>
            <img src={logo_url}
                 alt={manufacturer.title} className={"max-h-20"} />
          </div>
        )}
        <span className={"text-sm text-center"}>{manufacturer.title}</span>
      </div>
    </Link>
  )
}

interface LogoGridProps {
  omniclassName: string;
}

export const LogoGrid = ({ omniclassName }: LogoGridProps) => {
  const [manufacturers, setManufacturers] = useState<AnnotatedManufacturer[]>([]);
  const [loading, setLoading] = useState(true);
  const [showGrid, setShowGrid] = useState(false);

  /**
   * Show the grid after a short delay
   *
   * This is to prevent the loading element from flashing if the data is retrieved from the cache.
   */
  useEffect(() => {
    const timer = setTimeout(() => setShowGrid(true), 100);
    return () => clearTimeout(timer);
  }, []);

  /**
   * Fetch the manufacturers for the given omniclass
   *
   * If the data is already stored in Cache Storage, it will be used instead of fetching from the API.
   * The omniclass name is stored in the Cache Storage to ensure that the data is only used for the correct omniclass.
   * Cached data remains valid for 1 hour.
   */
  useEffect(() => {
    // fetch from endpoint then store in cache
    async function fetchData() {
      setManufacturers([]);
      setLoading(true);

      const data = await getManufacturerOverview(omniclassName);
      setManufacturers(sortManufacturers(data));
      setLoading(false);
    }

    fetchData();
  }, [omniclassName]);

  if (!showGrid) return null;

  return (
    <div className={"flex flex-col gap-4 items-center px-8"}>
      <h1 className={"text-gray-500 text-xl md:text-2xl text-center w-full"}>Manufacturers</h1>
      <p className={"text-gray-400 font-sm text-center"}>
        Here are manufacturer quick links {omniclassName}. Click a manufacturer to visit their homepage.
      </p>
      {loading && (
        <div className={"w-full md:w-[75%] lg:w-[60%] text-center"}>
          <span>Loading...</span>
          <LinearProgress />
        </div>
      )}
      <div className={"grid grid-cols-2 md:grid-cols-3 gap-16 md:px-32 pb-16"}>
        {manufacturers.map((manufacturer) => (
          <ManufacturerCell
            key={manufacturer.title}
            manufacturer={manufacturer}
            logo_url={get_logo_url(manufacturer)}
          />
        ))}
      </div>
    </div>
  )
}