import React, { useState, useEffect } from 'react';
import { OmniclassWithManufacturers, Omniclass } from "@/types/Omniclass";
import {getLogosWithOmniclassVariants} from "@/api/services/manufacturerService";
import {SearchComponent} from "@/components/searches/components/SearchComponent";
import {looselyFilterOmniclassOptions, useSearchInput} from "@/components/searches/hooks/useSearchInput";

type Option = Omniclass | OmniclassWithManufacturers;

const isOmniclassWithManufacturers = (option: Option, checkLength: boolean = true): option is OmniclassWithManufacturers => {
  if (checkLength) {
    return (option as OmniclassWithManufacturers).manufacturer_titles !== undefined &&
      (option as OmniclassWithManufacturers).manufacturer_titles.length > 0;
  }
  return (option as OmniclassWithManufacturers).manufacturer_titles !== undefined;
};

export interface SearchInputProps {
  fetchOptions: () => Promise<Option[]>;
  selectedOption: Omniclass | null;
  setSelectedOption: (option: Omniclass | null) => void;
  handleAdvancedSearch: (value: string, manufacturerTitle?: string) => void;
}

export const OmniclassSearchInput: React.FC<SearchInputProps> = ({
  fetchOptions,
  selectedOption,
  setSelectedOption,
  handleAdvancedSearch,
}) => {
  const {
    loading,
    inputValue,
    isOpen,
    showButton,
    setInputValue,
    setIsOpen,
    setShowButton,
    filteredOptions,
    handleInputChange,
  } = useSearchInput<Option>(
    fetchOptions, looselyFilterOmniclassOptions,
    setSelectedOption,
  );

  const [expandedOption, setExpandedOption] = useState<number | null>(null);
  const [manufacturerLogos, setManufacturerLogos] = useState<Record<string, string>>({});

  useEffect(() => {
    getLogosWithOmniclassVariants().then(setManufacturerLogos);
  }, []);

  useEffect(() => {
    if (selectedOption) {
      setInputValue(selectedOption.name);
      setShowButton(true);
    }
  }, [selectedOption]);


  const handleOptionClick = (option: Option, index?: number) => {
    if (isOmniclassWithManufacturers(option) && index !== undefined) {
      setExpandedOption(expandedOption === index ? null : index);
      return;
    } else {
      setExpandedOption(null);
      setInputValue(option.name);
      setSelectedOption(option);
      setIsOpen(false);
      setShowButton(true);
    }
  };

  const handleGenericOmniclassClick = (value: Omniclass) => {
    setInputValue(value.name);
    setIsOpen(false);
    setSelectedOption(value);
  }

  const handleSubmit = () => {
    if (!selectedOption) return;
    setSelectedOption(selectedOption);
    handleAdvancedSearch(selectedOption.name);
  }

  const renderDropdownTable = () => (
    <>
        <table className="bg-white w-full border">
          <thead className="sticky top-0 bg-gray-100 border-b">
            <tr>
              <th className="py-2 pl-4 text-sm text-left font-medium border-r">Omniclass #</th>
              <th className="py-2 pl-4 text-left font-medium">Omniclass Name</th>
            </tr>
          </thead>
          <tbody>
            {filteredOptions.map((option, index) => (
              <React.Fragment key={index}>
                <tr
                  onClick={() => handleOptionClick(option, index)}
                  className="hover:bg-primary hover:text-white cursor-pointer items-center gap-2 border-b"
                >
                  <td className="py-2 pl-4 text-sm">{option.number}</td>
                  <td className={`py-2 pl-4 border-l font-semibold`}>
                    {option.name}
                  </td>
                </tr>
                {expandedOption === index && isOmniclassWithManufacturers(option) && option.manufacturer_titles.length > 0 && (
                  <>
                    <tr onClick={() => handleGenericOmniclassClick(option)} className="cursor-pointer">
                      <td colSpan={2} className="py-2 pl-4 bg-gray-50">
                        <span>View Generic Omniclass</span>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="py-2 pl-4 bg-gray-50">
                        <ul className="flex items-end gap-8 overflow-x-auto">
                          {option.manufacturer_titles.map((title, idx) => (
                            <li key={idx} className="py-1 flex flex-col items-center gap-2 cursor-pointer text-lg"
                                onClick={() => handleAdvancedSearch(option.name, title)}>
                              {manufacturerLogos[title] && (
                                <img src={manufacturerLogos[title]} alt={`${title} logo`} className="max-h-16 max-w-32" />
                              )}
                              {title}
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  </>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      {inputValue.length > 0 && !selectedOption && (
        <div className="px-4 py-2 hover:bg-gray-200 hover:rounded-b-md text-gray-600 text-center border-t"
          onClick={() => handleAdvancedSearch(inputValue)}>
          Can't find what you're looking for? Select to generate a new omniclass.
        </div>
      )}
    </>
  );

  return (
    <SearchComponent
      inputValue={inputValue}
      handleInputChange={handleInputChange}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      buttonText={<><span className={"inline sm:hidden"}>Adv.</span><span className={"hidden sm:inline"}>Advanced</span>&nbsp;Search</>}
      showButton={showButton}
      handleSubmit={handleSubmit}
      placeholderText="Omniclass / Product Name"
      showButtonAfterSelect={true}
      renderDropdownTable={renderDropdownTable}
      loading={loading}
    />
  );
};