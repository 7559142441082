import {HTMLAttributes} from "react";
import {Menu} from "@/admin/components/Menu";
import {ContentWrapper} from "@/components/ContentWrapper";

/**
 * This adds the `Menu` component to the top of a `ContentWrapper` instance
 */
export const AdminPageWrapper = ({children, className}: HTMLAttributes<HTMLDivElement>) => (
  <ContentWrapper className={className}>
    <Menu />
    {children}
  </ContentWrapper>
)