import {useMemo, useState} from "react";

// Maximum number of manufacturers that can be selected by the user.
export const MAX_MANUFACTURER_SELECTION = 5

export const useManufacturerSelection = () => {
  const [selectedManufacturers, setSelectedManufacturers] = useState<string[]>([])

  const hasReachedMaxManufacturerSelection = useMemo(() => !(selectedManufacturers.length < MAX_MANUFACTURER_SELECTION), [selectedManufacturers])

  const handleSelectManufacturer = (manufacturer: string) => {
    if (selectedManufacturers.includes(manufacturer)) {
      setSelectedManufacturers(selectedManufacturers.filter(m => m !== manufacturer))
    } else {
      if (!hasReachedMaxManufacturerSelection) {
        setSelectedManufacturers([...selectedManufacturers, manufacturer])
      }
    }
  }

  return {selectedManufacturers, setSelectedManufacturers, handleSelectManufacturer, hasReachedMaxManufacturerSelection}
}