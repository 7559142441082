import {useEffect, useState} from "react";
import {SUPABASE} from "@/lib/db";
import { Session } from "@supabase/supabase-js";

export const useSession = () => {
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState<Session | null>(null)

  useEffect(() => {
    SUPABASE.auth.getSession().then(({data: {session}}) => {
      setSession(session)
      setLoading(false)
    })

    const {
      data: {subscription},
    } = SUPABASE.auth.onAuthStateChange((_event, session) => {
      setSession(session)
      setLoading(false)

      if (session) {
        SUPABASE.auth.signOut(
          {scope: 'others'}
        ).then(() => {})
      }
    })

    return () => subscription.unsubscribe()
  }, [])

  return {session, loading};
}