import {AnnotatedManufacturer} from "@/types/Manufacturer";


/**
 * Check if the manufacturer has any supplemental links (ie: links for professionals or additional links)
 *
 * @param manufacturer - The manufacturer to check
 *
 * @returns True if the manufacturer has any supplemental links, false otherwise
 */
export const manufacturerHasSupplementalLinks = (manufacturer: AnnotatedManufacturer): boolean => {
  const noSupplementalLinks =
    (!manufacturer.links_for_professionals && !manufacturer.additional_links) ||
    (manufacturer.links_for_professionals?.length === 0 && manufacturer.additional_links?.length === 0);
  return !noSupplementalLinks;
}

/**
 * Check if the manufacturer has "For Professionals" links.
 *
 * @param manufacturer - The manufacturer to check
 *
 * @returns True if the manufacturer has "For Professionals" links, false otherwise
 */
export const manufacturerHasLinksForProfessionals = (manufacturer: AnnotatedManufacturer): boolean => {
  if (!manufacturer.links_for_professionals) {
    return false;
  }
  return manufacturer.links_for_professionals.length > 0;
}