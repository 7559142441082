import { Parameter } from "@/types/Parameters";

/**
 * This function will take in the name of the omniclass table and the parameters and return a csv file
 *
 * The CSV file will contain the name of the parameters as the header and the values of the parameters as the rows
 * where each column represents a parameter and each row represents a value of the parameters.
 *
 * This is exported to allow for testing.
 *
 * @param parameters - The list of parameters to be formatted into a csv file
 */
export const format_parameters_into_csv  = (parameters: Parameter[]): string => {
  // Create the header of the csv file
  let csv = "";
  for (let i = 0; i < parameters.length; i++) {
    // escape commas in the parameter names
    if (parameters[i].name.includes(",")) {
      csv += `"${parameters[i].name}"`;
    } else {
      csv += parameters[i].name;
    }

    if (i !== parameters.length - 1) {
      csv += ",";
    }
  }
  csv += "\n";

  // Create the rows of the csv file
  for (let i = 0; i < parameters[0].values.length; i++) {
    for (let j = 0; j < parameters.length; j++) {

      // ensure that values are properly escaped
      const value = parameters[j].values[i];
      if (value.includes(",")) {
        csv += `"${parameters[j].values[i]}"`;
      } else {
        csv += value;
      }

      // add a comma if it is not the last value in the row
      if (j !== parameters.length - 1) {
        csv += ",";
      }
    }
    csv += "\n";
  }

  return csv;
}


/**
 * This function downloads the omniclass table as a csv file
 * @param omniclass_name - The name of the omniclass table. Used for the filename.
 * @param parameters - The list of parameters to be formatted into a csv file
 * @param appendText - The text to append to the filename. If not provided, the filename will be the omniclass name.
 */
export const downloadOmniclassCsv = async (omniclass_name: string, parameters: Parameter[], appendText?: string) => {
  const data= format_parameters_into_csv(parameters);
  const blob = new Blob([data], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  const filename = appendText ? `${omniclass_name} ${appendText}.csv` : `${omniclass_name}.csv`;
  link.setAttribute('download', filename);
  link.click();
  URL.revokeObjectURL(url);
}