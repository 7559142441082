import React from "react";
import {useLoaderData, useNavigate} from "react-router-dom";

import {useResultsContainer} from "./hooks/useResultsContainer";
import {useComponentSize} from "@/app/hooks/useComponentSize";

import { Header } from "@/app/components/header";
import {ParameterSection} from "@/app/components/ParameterSection";
import {ValueSection} from "@/app/components/ValueSection";
import {ManufacturerList} from "./components/ManufacturerList";
import {OmniclassValue} from "@/app/components/OmniclassValue";
import {Button} from "@/components/ui/Button";
import {UIButton} from "@/app/components/button";
import {TableDownloadButton} from "./components/TableDownloadButton";

import {OmniclassTableInfo} from "@/types/OmniclassTableInfo";

import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import {Footer} from "@/app/components/Footer";

/**
 * Main application component
 */
export const AdvancedSearchPage = () => {
  const info = useLoaderData() as OmniclassTableInfo;

  const navigate = useNavigate();

  const {
    selectedValues,
    handleSelectValue,
    manufacturers,
    loading,
    shouldHighlight,
    changeSortMethod,
    selectedManufacturers,
    handleSelectManufacturer,
    hasReachedMaxManufacturerSelection,
    selectedParameterName,
    setSelectedParameterName,
    selectedParameter,
    expanded,
    setExpanded,
    showExpandButton,
  } = useResultsContainer(info);

  const [maxHeightRef, { height }] = useComponentSize();

  return (
    <div className={"min-h-[100vh] flex flex-col justify-center"}>

      <Header />

      {/* Info & Controls */}
      <div className={"flex flex-row items-center p-4 gap-2"}>
        <Button
          variant={"ghost"}
          className={"flex items-center text-gray-400 hover:text-gray-500"}
          onClick={() => navigate(`/?selectedOption=${info.name}`)}
        >
          <ChevronLeftIcon className={"w-5 h-5 mr-2 -ml-1.5 stroke-2"} />
          Back to Start
        </Button>
        <div className={"flex-grow flex flex-row items-center"}>
          <OmniclassValue omniclass={info.name} />
        </div>

        {/* Download button - Removed until CSV format is establish */}
        {/*<TableDownloadButton tableInfo={info} disabled={false}/>*/}

        <UIButton onClick={() => navigate("/")}>
          Start New Search
        </UIButton>
      </div>

      {/* Main table content */}
      <section className={"grid grid-cols-3 gap-4 p-8 bg-secondary flex-grow"}>
        <div ref={maxHeightRef} className={"max-h-fit"}>
          <ParameterSection
            parameters={info.parameters}
            selectedValues={selectedValues}
            selectParameter={setSelectedParameterName}
            selected={selectedParameterName}
            deselectValue={(parameter_name) => handleSelectValue(parameter_name, '')}
          />
        </div>
        <div style={{maxHeight: `${height}px`}}>
          <ValueSection
            parameter={selectedParameter}
            selectedValues={selectedValues}
            handleSelectValue={handleSelectValue}
          />
        </div>
        <div style={{maxHeight: `${height}px`}}>
          <ManufacturerList
            manufacturers={manufacturers}
            loading={loading}
            shouldHighlight={shouldHighlight}
            omniclass={info.name}
            selected={selectedManufacturers}
            onSelect={handleSelectManufacturer}
            selectedParameters={selectedValues}
            omniclassName={info.name}
            changeSortMethod={changeSortMethod}
            hasReachedMaxSelection={hasReachedMaxManufacturerSelection}
            expanded={expanded}
            setExpanded={setExpanded}
            showExpandButton={showExpandButton}
          />
        </div>
      </section>

      <Footer wide={true} />
    </div>
  )
}