import {AnnotatedManufacturer, SupplementalLink} from "@/types/Manufacturer";
import {SectionTitle} from "@/app/pages/ManufacturerDetails/components/SectionTitle";

export const LinksSection = ({manufacturer}: { manufacturer: AnnotatedManufacturer }) => {
  return (
    <>
      <LinksSubsection title={"Links for Professionals"} links={manufacturer.links_for_professionals}/>

      <LinksSubsection title={"Additional Links"} links={manufacturer.additional_links}/>
    </>
  )
}

const LinksSubsection = ({title, links}: { title: string, links?: SupplementalLink[] | null }) => {
  if (links && links.length > 0) {
    return (
      <div className={"flex flex-col gap-2"}>
        <SectionTitle className={"text-lg"}>{title}</SectionTitle>
        <ul className={"flex flex-col"}>
          {links.map(link => (
            <li key={link.url} className={"hover:bg-sky-50 hover:underline px-4 py-2 rounded-md"}>
              <a href={link.url} className={"text-sky-600"} target={"_blank"} rel={"noreferrer"}>
                {link.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    )
  } else {
    return <></>
  }
}