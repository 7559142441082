import React from "react";
import {OmniclassValue} from "@/app/components/OmniclassValue";
import {UIButton} from "@/app/components/button";
import { Link } from "react-router-dom";

export const SearchBar = ({omniclass}: {omniclass: string}) => (
  <div className={"flex flex-row items-start p-4 gap-2 shadow-sky-100 shadow-md"}>
    <div className={"flex-grow flex flex-row items-center"}>
      <OmniclassValue omniclass={omniclass} />
    </div>

    <Link to={"/"}>
      <UIButton>
        Start New Search
      </UIButton>
    </Link>
  </div>
)