import React from "react";
import {Card} from "@/components/ui/card";

/**
 * A card that is centered on the page.
 *
 * This is a helper component used for layout purposes and used in the auth pages, as well as `FirstLogin.tsx`.
 *
 */
export const CenteredCard = ({ children, className }: { children: React.ReactNode, className?: string }) => (
  <div className={`flex items-center justify-center h-[100vh] ${className}`}>
    <Card className={"w-[95vw] sm:w-[65vw] md:w-[50vw] lg:w-[30vw]"}>
      {children}
    </Card>
  </div>
);
