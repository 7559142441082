import {useState} from 'react';

import { Parameter } from '@/types/Parameters';

/**
 * React hook to manage selected parameter values
 *
 * @param parameters - The list of all possible parameter-values to select for
 */
export const useSelectedParameters = (parameters: Parameter[]) => {
  // create a list of columns from the list of parameters
  // leave a blank column for each parameter
  const [selectedValues, setSelectedValues] = useState(
    parameters.map((parameter) => ({
      name: parameter.name,
      value: ''
    }))
  )

  /**
   * Select or de-select a value for a parameter
   *
   * This is used as a callback function within the `ResultsTable` component
   *
   * @param parameterName {string} - The name of the parameter
   * @param value {string} - The value to select/de-select
   */
  const handleSelectValue = (parameterName: string, value: string) => {
    setSelectedValues(prevParameters =>
      prevParameters.map(parameter =>
        parameter.name === parameterName
          ? { ...parameter, value: parameter.value === value ? '' : value }
          : parameter
      )
    );
  }

  return { selectedValues, handleSelectValue, setSelectedValues };
};