import React from "react";
import {cn} from "@/lib/utils";


export const selectedColor = "bg-blue-100";
export const hoveredColor = "hover:bg-blue-100";
export const highlightedColor = "bg-yellow-200";

export const SectionItem = ({className, children, ...props}: React.HTMLAttributes<HTMLLIElement>) => {
  return (
    <li {...props} className={cn(
      "px-4 py-2 cursor-pointer first:mt-3",
      className
    )}>
      {children}
    </li>
  )
}

export const SectionContainer = ({className, children, ...props}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div {...props} className={cn(
      "flex flex-col h-full rounded-md bg-white",
      className
    )}>
      {children}
    </div>
  )
}

export const SectionHeader = ({className, children, ...props}: React.HTMLAttributes<HTMLHeadingElement>) => {
  return (
    <h2 {...props} className={cn(
      "border-b-2 border-slate-100 px-4 py-3 text-white font-bold bg-primary rounded-t-md",
      className
    )}>
      {children}
    </h2>
  )
}