import React, {useEffect, useMemo, useState} from "react";
import {Omniclass} from "@/types/Omniclass";

/**
 * User-facing filter for Omniclass options
 *
 * This filter splits user input into words, and performs a loose search on the Omniclass options. The goal is to loosely
 * match the user input with the available Omniclass options. Unlike `filterOmniclassOptions`, this filter does not
 * require the user input to exactly match the Omniclass name or number.
 *
 * @param options
 * @param inputValue
 */
export const looselyFilterOmniclassOptions = (options: Omniclass[], inputValue: string) => {
  let cleaned = inputValue.trim();
  if (!cleaned) return options;

  // Remove non-alphanumeric characters from the input value
  const sanitize = (str: string) => str.replace(/[^a-z0-9]/gi, '').toLowerCase();

  // Split the input value into words, remove non-alphanumeric characters them, and filter out empty strings
  const words = cleaned.split(' ')
    .filter(word => word.trim())
    .map(sanitize)
    .filter(word => word);
  return options.filter(option =>
    words.some(word =>
      option.name.toLowerCase().includes(word.toLowerCase()) ||
      (option.number && option.number.toLowerCase().includes(word.toLowerCase()))
    )
  );
}

export const filterOmniclassOptions = (options: Omniclass[], inputValue: string) => {
  if (!inputValue) return options;
  return options.filter(option => {
      if (option.number) {
        return option.name.toLowerCase().includes(inputValue.toLowerCase()) || option.number.toLowerCase().includes(inputValue.toLowerCase())
      }
      return option.name.toLowerCase().includes(inputValue.toLowerCase())
    }
  );
}

export const filterManufacturerOptions = (options: string[], inputValue: string) => {
  if (!inputValue) return options;
  return options.filter(option =>
    option.toLowerCase().includes(inputValue.toLowerCase())
  );
}

export function useSearchInput<T>(
  fetchOptions: () => Promise<T[]>,
  filterOptions: (options: T[], inputValue: string) => T[],
  setSelectedOption?: (option: T | null) => void,
) {
  const [options, setOptions] = useState<T[]>([]);
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    fetchOptions()
      .then(fetchedOptions => {
        setOptions(fetchedOptions);
      }).then(() => setLoading(false))
      .catch(error => console.error('Error fetching options:', error));
  }, [fetchOptions]);

  const filteredOptions = useMemo(() => {
    return filterOptions(options, inputValue);
  }, [options, inputValue]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    setShowButton(!!value);
    setIsOpen(true);

    if (setSelectedOption) setSelectedOption(null);
  };

  return {
    options,
    loading,
    inputValue,
    isOpen,
    showButton,
    setOptions,
    setLoading,
    setInputValue,
    setIsOpen,
    setShowButton,
    filteredOptions,
    handleInputChange,
  }
}
