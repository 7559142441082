import React, {useEffect, useState} from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription, DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "@/components/ui/Dialog";
import {Button} from "@/components/ui/Button";
import {Label} from "@/components/ui/label";
import {Textarea} from "@/components/ui/textarea";
import { Input } from "@/components/ui/Input";
import {Badge} from "@/components/ui/badge";
import { toast } from "@/components/ui/use-toast";
import {SelectedParameter} from "@/types/Parameters";
import {generateRfiMessage, sendRfiRequest} from "@/api/services/omniclass";
import {FileRequest, FileRequestValues} from "@/types/RFIRequest";
import {Checkbox} from "@/components/ui/checkbox";
import {XMarkIcon} from "@heroicons/react/24/outline";


interface EmailRequestDialogProps {
  manufacturers: string[];
  deselectManufacturer?: (manufacturer: string) => void;
  omniclassName: string;
  selectedParameters: SelectedParameter[];
  buttonText?: string;
}

export const EmailRequestDialog = ({manufacturers, deselectManufacturer, omniclassName, selectedParameters, buttonText}: EmailRequestDialogProps) => {
  const [email, setEmail] = useState("")
  const [emailContent, setEmailContent] = useState("")
  const [name, setName] = useState("");
  const [fileRequests, setFileRequests] = useState<FileRequest[]>([])

  // controls the state of the dialog
  const [open, setOpen] = useState<boolean>(false);

  const handleFileRequestSelect = (fileRequest: FileRequest) => {
    if (fileRequests.includes(fileRequest)) {
      setFileRequests(fileRequests.filter((request) => request !== fileRequest));
    } else {
      setFileRequests([...fileRequests, fileRequest]);
    }
  }

  const filteredParameters = selectedParameters.filter((parameter) => parameter.value !== "");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    sendRfiRequest(email, name, fileRequests, emailContent, manufacturers, omniclassName, filteredParameters).then(() => {
      // only close the dialog if the request was successful
      setOpen(false);

      // show success message toast
      toast({
        title: "Request Sent",
        description: "Your request has been sent successfully."
      });
    }).catch((error) => {
      // TODO: show error message
      console.error("Error sending request:", error);
    });
  }

  const regenerateEmailContent = () => {
    generateRfiMessage(omniclassName, filteredParameters, fileRequests).then(data => {
      setEmailContent(data);
    })
  }

  useEffect(() => {
    regenerateEmailContent();
  }, [fileRequests]);

  return (
    <Dialog open={open} onOpenChange={setOpen} >
      <DialogTrigger asChild>
        <Button className={"bg-rose-600 hover:bg-rose-700"} size={"sm"}
                disabled={(filteredParameters.length === 0 || manufacturers.length === 0)}
                onClick={regenerateEmailContent}>
          { buttonText ? buttonText : "Request Info" }
        </Button>
      </DialogTrigger>
      <DialogContent className={"min-w-[70vw]"}>
        <DialogHeader>
          <DialogTitle>Request Information</DialogTitle>
          <DialogDescription>
            Send a RFI to these manufacturers for their products that match your selected parameters.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <div className={"flex flex-col lg:flex-row gap-4"}>
            <div className={"flex flex-col max-w-[40%]"}>
              {/* Manufacturer Names */}
              <div>
                <h1 className={"text-lg"}>Manufacturers</h1>
                <p className={"text-sm text-gray-500"}>
                  These are the manufacturers that will receive the request.
                </p>
                <ul className={"mt-1 flex flex-wrap gap-2"}>
                  {manufacturers.map((manufacturer, index) => (
                    <li key={index}>
                      <Badge className={"text-base font-thin bg-primary/70 text-white"}>
                        {manufacturer}
                        {deselectManufacturer && <XMarkIcon className={"h-4 w-4 ml-1 hover:cursor-pointer"}
                                                            onClick={() => deselectManufacturer(manufacturer)} />}
                      </Badge>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className={"flex flex-col"}>
              {/* Name */}
              <div>
                <Label htmlFor={"name"}>
                  Name
                </Label>
                <Input
                  id="name"
                  type="text"
                  autoComplete={"name"}
                  placeholder={"Name (optional)"}
                  value={name}
                  onChange={e => setName(e.target.value)}
                  required />
              </div>

              {/* Return Email */}
              <div className={"mt-1"}>
                <Label htmlFor="email">
                  Return Email
                </Label>
                <p className={"text-sm text-gray-500"}>
                  The information will get forwarded to your inbox.
                </p>
                <Input
                  id="email"
                  type="email"
                  autoComplete={"email"}
                  placeholder={"Email"}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required />
              </div>

              {/* TODO: add checkbox to remain anonymous */}

              {/* Email Content */}
              <div className={"mt-1"}>
                <Label htmlFor="email_content">
                  Email Content
                </Label>
                <Textarea
                  id="email_content"
                  className={"min-h-[24ch]"}
                  value={emailContent}
                  onChange={e => setEmailContent(e.target.value)}
                  required />
              </div>

              {/* File Request Types */}
              <div>
                <h3 className={"text-md pt-2 pb-1.5"}>File Request Types</h3>
                <div className={"grid grid-cols-3 gap-2"}>
                  {FileRequestValues.map((fileRequest, index) => (
                    <div className={"flex gap-2"} key={index}>
                      <Checkbox id={fileRequest} name={fileRequest} onClick={() => handleFileRequestSelect(fileRequest as FileRequest)}
                                checked={fileRequests.includes(fileRequest as FileRequest)} />
                      <Label htmlFor={fileRequest}>{fileRequest}</Label>
                    </div>
                  ))}
                </div>
              </div>

              {/* Submit Button */}
              <DialogFooter>
                <Button
                  type="submit"
                  disabled={manufacturers.length > 0}
                >
                  Send Request
                </Button>
              </DialogFooter>

            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}