import LogoFull from "@/app/assets/logo_full.png";
import React from "react";
import {Link} from "react-router-dom";


export const Header = () => {
  return (
    <div className={`flex flex-col w-full items-center p-4 border-b-2 border-secondary`}>
      <div className={`flex flex-row items-center gap-4 w-full`}>
        <div className={"flex-grow"}>
          <div className={"w-fit"}>
            <Link to={"/"}>
              <img src={LogoFull}
                   alt={"logo"}
                   className={`px-2 max-h-[1.5rem] -my-1`}/>
            </Link>
          </div>
        </div>
        <h1 className={`text-xl font-bold text-primary`}>SCOUT&#8482; Product Search</h1>
      </div>
    </div>
  )
}