import {Contact, MinimalContact} from "@/types/Contact";
import {adminEndpoints, apiEndpoints} from "@/api/endpoints";
import {checkSuccess} from "@/api/services/utils";

/**
 * Create a new contact
 *
 * @param contact - The contact to create
 *
 * @throws {Error} - If the request fails
 */
export const createContact = async (contact: Contact) => {
  const encodedTitle = encodeURIComponent(contact.manufacturer_title);
  const url = `${adminEndpoints.contact.create}${encodedTitle}`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(contact)
  });
  await checkSuccess(response);
}

/**
 * Update a contact
 *
 * @param contact
 *
 * @throws {Error} - If the request fails
 */
export const updateContact = async (contact: Contact) => {
  const url = `${adminEndpoints.contact.update}${contact.id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(contact)
  });
  await checkSuccess(response);
}

/**
 * Delete a contact
 *
 * @param id - The id of the contact to delete
 *
 * @throws {Error} - If the request fails
 */
export const deleteContact = async (id: number) => {
  const url = `${adminEndpoints.contact.delete}${id}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  await checkSuccess(response);
}

// noinspection JSUnusedGlobalSymbols
/**
 * Get a contact by id
 *
 * @param id
 *
 * @throws {Error} - If the request fails
 */
export const getContact = async (id: number): Promise<Contact> => {
  const url = `${adminEndpoints.contact.get}${id}`;
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return await response.json();
}

export const getMinimalContacts = async (manufacturerTitle: string): Promise<MinimalContact[]> => {
  const encodedTitle = encodeURIComponent(manufacturerTitle);

  const url = `${apiEndpoints.get_contacts}${encodedTitle}`;
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return await response.json();
}

/**
 * Get all contacts for a manufacturer
 *
 * @param manufacturer_title
 *
 * @throws {Error} - If the request fails
 */
export const getAllContacts = async (manufacturer_title: string): Promise<Contact[]> => {
  const encodedTitle = encodeURIComponent(manufacturer_title);
  const url = `${adminEndpoints.contact.all}${encodedTitle}`;

  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return await response.json();
}