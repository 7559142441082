/**
 * Encode a string for use in a URL
 *
 * This is used to create URLs that are human-readable
 *
 * @param s - The string to encode
 */
export const encode_readable_url = (s: string) => {
  return s.replace(/ /g, "_");
}

/**
 * Decode strings encoded with `encode_readable_url`
 *
 * @param s - The string to decode
 */
export const decode_readable_url = (s: string) => {
  return s.replace(/_/g, " ");
}