import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";

export const OmniclassValue = ({ omniclass }: { omniclass: string }) => (
  <div className="flex gap-2 items-center px-2 bg-secondary flex-grow rounded-3xl">
    <MagnifyingGlassIcon className="h-6 w-6 text-white bg-gray-400 p-1 rounded-3xl" style={{strokeWidth: 3}}/>
    <label htmlFor="productName" className="sr-only">Product Name</label>
    <input
      id="productName"
      type="text"
      value={omniclass}
      className={"bg-secondary w-full py-1.5 pl-2 rounded-3xl focus:outline-none text-gray-500"}
      placeholder="Product Name / Omniclass"
      disabled
    />
  </div>
)
