import {useEffect, useState} from "react";
import {Omniclass} from "@/types/Omniclass";
import {Button} from "@/components/ui/Button";
import {Switch} from "@/components/ui/switch";
import {Label} from "@/components/ui/label";
import {HelpText} from "@/admin/components/HelpText";
import {getMissingManufacturers} from "@/api/services/omniclass";
import { Link } from "react-router-dom";
import { AdminPageWrapper } from "@/admin/components/AdminPageWrapper";
import {Skeleton} from "@/components/ui/Skeleton";
import {OmniclassRows} from "@/admin/components/OmniclassRows";


export const MissingManufacturers = () => {
  const [omniclasses, setOmniclasses] = useState<Omniclass[] | null>(null);
  const [reviewed, setReviewed] = useState(false);

  // Fetch the missing manufacturers from the server
  useEffect(() => {
    getMissingManufacturers(reviewed)
      .then(data => {
        setOmniclasses(data)
      })
      .catch(error => {
        console.log(error);
        setOmniclasses([]);
      })
  }, [reviewed]);

  const onDeleteCallback = (omniclassName: string) => {
    if (omniclasses === null) {
      return
    }
    setOmniclasses(omniclasses.filter(item => item.name !== omniclassName));
  }

  const downloadCsv = () => {
    if (omniclasses === null) {
      return
    }

    // format the data into csv
    let csv = "";
    omniclasses.forEach(omniclass => {
      const num = omniclass.number ? omniclass.number : "";
      csv += `${num},"${omniclass.name}"\n`;
    });

    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'remaining.csv');
    link.click();
  }

  return (
    <AdminPageWrapper>
      <div className={"flex flex-col gap-4 p-4"}>
        <h1 className={"text-2xl"}>Missing Manufacturers</h1>

        <p className={"text-sm font-light"}>
          These are the omniclasses which have no associated omniclasses. The `bimWorkshopDatabase` tool can be used to generate the manufacturer tables.
          The tables can then be uploaded using the&nbsp;
          <Link to={"/admin/manufacturer/upload"} className={"underline text-sky-600"}>
            manufacturer upload page
          </Link>.
        </p>

        <div className="flex items-center gap-2">
          <Switch id="reviewed"
                  checked={reviewed}
                  onCheckedChange={setReviewed} />
          <Label htmlFor="reviewed">Reviewed</Label>
          <HelpText>
            If enabled, unreviewed omniclasses (ie: user-generated) will be hidden.
          </HelpText>
        </div>

        <p className={"flex gap-2 items-center"}>
          <span className={"font-bold"}>
            Total:
          </span>
          {omniclasses === null ? <Skeleton className={"w-6 h-6"} /> : omniclasses.length}
        </p>

        <div className={"flex flex-row items-center gap-2"}>
          <DownloadButton handleDownload={downloadCsv}/>
          <HelpText>
            Downloads the "remaining.csv" file.
          </HelpText>
        </div>

        <OmniclassRows omniclasses={omniclasses} onDeleteCallback={onDeleteCallback} />
      </div>
    </AdminPageWrapper>
  )
}


interface DownloadButtonProps {
  handleDownload: () => void;
}


const DownloadButton = ({handleDownload}: DownloadButtonProps) => {
  return (
    <Button onClick={handleDownload}>
      Download
    </Button>
  )
}