import {MinimalContact} from "@/types/Contact";
import {SectionTitle} from "@/app/pages/ManufacturerDetails/components/SectionTitle";

const ContactItem = ({contact}: {contact: MinimalContact}) => {
  return (
    <a href={`mailto:${contact.email}`}
       className={"text-sm text-sky-600 hover:underline hover:bg-sky-50 rounded-md px-4 py-2"}>
      {contact.first_name} {contact.last_name} ({contact.title})
    </a>
  )
}

export const ContactsContent = ({contacts}: {contacts: MinimalContact[] | null}) => {
  if (contacts === null || contacts === undefined || contacts.length === 0) {
    return null;
  }

  return (
    <div className={"flex flex-col"}>
      <SectionTitle className={"text-lg"}>Contacts</SectionTitle>
      <div className={"flex flex-row gap-8"}>
        {contacts.map((contact, index) => <ContactItem key={index} contact={contact} />)}
      </div>
    </div>
  )
};
