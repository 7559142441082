import { adminEndpoints } from "../endpoints"
import {StatusData} from "@/types/StatusData";
import {checkSuccess} from "@/api/services/utils";
import {InvitedUser} from "@/types/users";

export const generateInviteLink = async (email: string): Promise<void> => {
  const body = {
    email,
    origin_url: window.location.origin
  }
  const response = await fetch(adminEndpoints.users.invite, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return await checkSuccess(response);
}

export const getInvitedUsers = async (): Promise<InvitedUser[]> => {
  const response = await fetch(adminEndpoints.users.invited_users, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const raw_data = await response.json();

  // map date strings to Date objects
  return raw_data.map((user: InvitedUser) => ({
    ...user,
    invited_at: new Date(user.invited_at)
  })) as InvitedUser[];
}

export const get_db_status = async (): Promise<StatusData> => {
  const response = await fetch(adminEndpoints.status);
  return await response.json();
}