import {SectionTitle} from "@/app/pages/ManufacturerDetails/components/SectionTitle";
import {AnnotatedManufacturer} from "@/types/Manufacturer";
import {VerifiedTag} from "@/app/components/VerifiedTag";
import {Link} from "react-router-dom";
import {Button} from "@/components/ui/Button";
import {PencilIcon} from "@heroicons/react/24/outline";


export const DetailHeader = ({manufacturer}: { manufacturer: AnnotatedManufacturer }) => (
  /* TODO: insert logo beside title */
  <div className={"flex flex-row gap-2 items-center mb-4"}>
    <SectionTitle className={"text-3xl"}>{manufacturer.title}</SectionTitle>

    {manufacturer.verified &&
      <VerifiedTag size={"header"} variant={"inverse"} />
    }

    <Button size={"sm"} variant={"secondary"} className={"ml-4"} asChild>
      <Link to={`/admin/manufacturer/edit/${manufacturer.title}`}>
        <PencilIcon className={"h-4 w-4 mr-2"} />
        Edit
      </Link>
    </Button>
  </div>
)