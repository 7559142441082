import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "@/components/ui/tooltip";

import {AggregatorContent} from "@/types/Manufacturer";

import ArchitizerLogo from "./img/architizer-logo.png";
import CadDetailLogo from "./img/caddetails-logo.png";
import BimObjectLogo from "./img/bimobject-logo.svg";
import BimSmithLogo from "./img/BIMSMITH-logo.webp";


interface TagProps {
  count?: number;
}

interface LowLevelTagProps {
  count?: number | null;
  name: string;
  logo: string;
}

/**
 * Common component for showing tooltip with the number of products on the aggregator site
 *
 * @param count - number of products (or -1 if there is a link but no count)
 * @param name - name of the aggregator site
 * @param logo - logo of the aggregator site
 */
const LowLevelTag = ({count, name, logo}: LowLevelTagProps) => {
  if (!count || count === 0) {
    return null;
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <img src={logo} alt={`${name} Logo`} className={"h-4"}/>
        </TooltipTrigger>
        <TooltipContent>
          <p data-testid={`${name} text`}>{count === -1 ? `Has files available on ${name}` : `${count} products on ${name}`}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
const ArchitizerTag = ({count}: TagProps) => <LowLevelTag count={count} name={"Architizer"}
                                                          logo={ArchitizerLogo}/>
const CadDetailsTag = ({count}: TagProps) => <LowLevelTag count={count} name={"CADDetails"}
                                                          logo={CadDetailLogo}/>
const BimObjectTag = ({count}: TagProps) => <LowLevelTag count={count} name={"BimObject"}
                                                         logo={BimObjectLogo}/>
const BimSmithTag = ({count}: TagProps) => <LowLevelTag count={count} name={"BIMSmith"}
                                                        logo={BimSmithLogo}/>


export const AllAggregatorTags = ({aggregatorContent}: { aggregatorContent: AggregatorContent | null | undefined }) => {
  if (!aggregatorContent) {
    return null;
  }

  return (
    <>
      {(aggregatorContent.bimsmith > 0 || aggregatorContent.bimsmith === -1) && <BimSmithTag count={aggregatorContent.bimsmith}/>}
      {(aggregatorContent.bimobject > 0 || aggregatorContent.bimsmith === -1) && <BimObjectTag count={aggregatorContent.bimobject}/>}
      {(aggregatorContent.caddetails > 0 || aggregatorContent.caddetails === -1) && <CadDetailsTag count={aggregatorContent.caddetails}/>}
      {(aggregatorContent.architizer > 0 || aggregatorContent.architizer === -1) && <ArchitizerTag count={aggregatorContent.architizer}/>}
    </>
    )
  }