import React from 'react';
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { UIButton } from "@/app/components/button";
import {LinearProgress} from "@/components/LinearProgress";

interface SearchComponentProps {
  inputValue: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  showButton: boolean;
  handleSubmit: () => void;
  placeholderText: string;
  showButtonAfterSelect?: boolean;
  buttonText?: string | React.ReactNode;
  renderDropdownTable: () => React.ReactNode;
  loading: boolean;
}

export const SearchComponent = ({
  inputValue,
  handleInputChange,
  isOpen,
  setIsOpen,
  showButton,
  handleSubmit,
  placeholderText,
  showButtonAfterSelect = false,
  renderDropdownTable,
  buttonText = "Search",
  loading,
}: SearchComponentProps) => (
  <div className="w-full">
    <div className="flex items-center gap-2">
      <div className="flex gap-2 items-center px-2 bg-secondary flex-grow rounded-3xl">
        <MagnifyingGlassIcon className="h-6 w-6 text-white bg-gray-400 p-1 rounded-3xl" style={{ strokeWidth: 3 }} />
        <label htmlFor="inputName" className="sr-only">{placeholderText}</label>
        <input
          id="inputName"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          className="bg-secondary w-full py-1.5 pl-2 rounded-3xl focus:outline-none"
          placeholder={placeholderText}
          autoComplete="off"
        />
      </div>
      {showButton && showButtonAfterSelect && (
        <UIButton type="button" onClick={handleSubmit}>
          {buttonText}
        </UIButton>
      )}
    </div>
    {isOpen &&
      <div className="shadow-lg rounded-md mt-1 max-h-80 overflow-auto">
        {loading ? (
          <div>
            <p className="text-center py-2 text-gray-500 font-semibold">Loading options...</p>
            <LinearProgress />
          </div>) : (
            renderDropdownTable()
        )}
        </div>
    }
  </div>
);