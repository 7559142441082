interface FooterProps {
  wide?: boolean;
}

export const Footer = ({wide}: FooterProps) => {
  const wrapperClass = wide ? "container mx-auto" : "p-4 sm:px-16 md:px-40 lg:px-60";

  return (
    <footer className="bg-primary/10 text-primary">
      <div className={`p-4 ${wrapperClass}`}>
        <div className="flex justify-between">
          <div className={"flex flex-col gap-2"}>
            <p>
              Have questions? Contact us at&nbsp;
              <a href="mailto:info@bimworkshop.com" className={"font-semibold"}>info@bimworkshop.com</a>
            </p>
            <p>© 2024 BIMWorkshop</p>
          </div>
          <div>
            <ul className={"flex flex-col gap-2"}>
              <li>
                <a href={"/privacy"} className={" font-thin"}>Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}