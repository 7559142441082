import {AnnotatedManufacturer, Manufacturer} from "@/types/Manufacturer";
import {adminEndpoints, apiEndpoints} from "../endpoints";
import {ManufacturerSpecificVariant, OmniclassTableInfo} from "@/types/OmniclassTableInfo";
import {Omniclass, OmniclassWithManufacturers} from "@/types/Omniclass";
import {checkSuccess} from "@/api/services/utils";
import {SelectedParameter} from "@/types/Parameters";
import {FileRequest, RFIRequest} from "@/types/RFIRequest";
import {ProductQuery} from "@/types/ProductQuery";


/**
 * Get a list of all available omniclasses
 *
 * Used for getting autocomplete options for the `SearchInput` component. The autocomplete options are meant for
 * user-facing applications and do not include omniclasses which do not any associated manufacturers.
 *
 * @returns {Promise<Omniclass>} A list of all available omniclasses.
 */
export const getAllUserFacingOmniclasses = async (): Promise<OmniclassWithManufacturers[]> => {
  const response = await fetch(apiEndpoints.all_omniclasses, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return await response.json();
}


/**
 * Get a list of all omniclasses for the admin panel.
 *
 * This is used to get all omniclasses, including those which do not have any associated manufacturers.
 */
export const getAllOmniclassesForAdmin = async (): Promise<OmniclassWithManufacturers[]> => {
  const response = await fetch(adminEndpoints.omniclass.all, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return await response.json();
}

/**
 * Get a list of all general omniclasses for the admin panel.
 *
 * This is used to get all general omniclasses, including those which do not have any associated manufacturers.
 */
export const getAllGeneralOmniclassesForAdmin = async (): Promise<Omniclass[]> => {
  const response = await fetch(adminEndpoints.omniclass.all_general, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return await response.json();
}

/**
 * Get the omniclass table data for the given identifier.
 *
 * In the backend, this either retrieves the data or generates it if it does not exist.
 * @param {string} identifier - The identifier of the omniclass table. This is not required to include the omniclass number.
 *
 * @returns {OmniclassTableInfo} The omniclass table data.
 */
export const getOmniclassTable = async (identifier: string): Promise<OmniclassTableInfo> => {
  const encodedIdentifier = encodeURIComponent(identifier);
  const response = await fetch(`${apiEndpoints.get_omniclass_table}?identifier=${encodedIdentifier}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  });
  return await response.json();
}

/**
 * Get the manufacturer-specific variant of an omniclass table data for the given identifier.
 *
 * @param identifier - The identifier of the omniclass table. This is not required to include the omniclass number.
 * @param manufacturerTitle - The title of the manufacturer.
 */
export const getManufacturerOmniclassVariant = async (identifier: string, manufacturerTitle: string): Promise<ManufacturerSpecificVariant> => {
  const encodedTitle = encodeURIComponent(manufacturerTitle);
  const encodedIdentifier = encodeURIComponent(identifier);
  const response = await fetch(
    `${apiEndpoints.get_omniclass_table}?identifier=${encodedIdentifier}&manufacturer_title=${encodedTitle}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  });

  return response.json();
}

/**
 * Get a list of manufacturers associated with the given omniclass.
 *
 * @param {string} omniclassName - The name of the omniclass.
 * @param {boolean} expanded - Flag which when true, returns manufacturers for the parent omniclass.
 *                             If no parent omniclass exists, this flag is ignored.
 *
 * @returns {Promise<AnnotatedManufacturer[]>} All manufacturers associated with the given omniclass.
 */
export const getAssociatedManufacturers = async (omniclassName: string, expanded: boolean = false): Promise<AnnotatedManufacturer[]> => {
  const encodedName = encodeURIComponent(omniclassName);
  const response = await fetch(`${apiEndpoints.all_manufacturers}?omniclass=${encodedName}&expanded=${expanded}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  });
  return await response.json();
}

/**
 * Update the manufacturers associated with the given omniclass.
 *
 * @param {string} identifier - The identifier of the omniclass.
 * @param {Manufacturer[]} manufacturers - The manufacturers to associate with the omniclass.
 *
 * @returns {Promise<void>} A promise that resolves when the manufacturers are updated.
 */
export const updateAssociatedManufacturers = async (identifier: string, manufacturers: Manufacturer[]): Promise<void> => {
  const response = await fetch(adminEndpoints.omniclass.update_manufacturers, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query: identifier,
      associations: manufacturers.map(value => value.title)
    })
  })

  await checkSuccess(response);
}

/**
 * Get a list of omniclasses which have not been reviewed.
 *
 * Since unreviewed omniclasses do not have a number by nature, this function returns a list of omniclass names.
 *
 * @returns {Promise<string[]>} A list of omniclass names which have not been reviewed.
 */
export const getUnreviewedOmniclasses = async (): Promise<string[]> => {
  const response = await fetch(adminEndpoints.omniclass.unreviewed);
  return await response.json();
}

/**
 * Delete the omniclass table with the given identifier.
 *
 * @param identifier - The identifier of the omniclass table. This is not required to include the omniclass number.
 */
export const deleteOmniclass = async (identifier: string): Promise<void> => {
  const encodedIdentifier = encodeURIComponent(identifier);
  const response = await fetch(`${adminEndpoints.omniclass.delete}${encodedIdentifier}`, {
    method: 'DELETE',
  });

  await checkSuccess(response);
}

/**
 * Get the omniclass table data for the given identifier.
 *
 * This uses the admin endpoint to get the data. Unlike `get_omniclass_table`, this does not generate the data if it
 * does not exist.
 *
 * @param identifier - The identifier of the omniclass table. This is not required to include the omniclass number.
 */
export const getOmniclass = async (identifier: string): Promise<OmniclassTableInfo> => {
  const encodedIdentifier = encodeURIComponent(identifier)
  const response = await fetch(`${adminEndpoints.omniclass.get}?name=${encodedIdentifier}`);
  return await response.json();
}

/**
 * Retrieve a list of omniclasses that are missing manufacturers.
 *
 * @param reviewed - Flag which if true, only returns reviewed omniclasses.
 *
 * @returns {Promise<Omniclass[]>} A list of omniclasses that are missing manufacturers.
 */
export const getMissingManufacturers = async (reviewed: boolean): Promise<Omniclass[]> => {
  const url = reviewed ? `${adminEndpoints.omniclass.missing_manufacturers}?reviewed=1` : adminEndpoints.omniclass.missing_manufacturers;
  const response = await fetch(url);
  return await response.json()
}


/**
 * Send an RFI request to the selected manufacturers
 */
export const sendRfiRequest = async (user_email: string,
                                     user_name: string,
                                     fileRequests: FileRequest[],
                                     emailContent: string,
                                     manufacturers: string[],
                                     omniclass: string,
                                     parameters: SelectedParameter[]): Promise<void> => {
  const request_body: RFIRequest = {
    query: {
      omniclass,
      parameters
    },
    manufacturer_titles: manufacturers,
    email_content: emailContent,
    user_email,
    user_name,
    fileRequests
  }

  const response = await fetch(apiEndpoints.send_rfi_request, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request_body)
  });

  await checkSuccess(response);
}


/**
 * Generate RFI message text based on the user's RFI query.
 *
 * The backend uses an LLM to generate the message.
 *
 * @param omniclass - The omniclass name
 * @param parameters - The selected parameters
 * @param fileRequests - The types of files the user is requesting
 *
 * @returns {Promise<string>} The generated RFI message
 */
export const generateRfiMessage = async (omniclass: string, parameters: SelectedParameter[], fileRequests: FileRequest[]): Promise<string> => {
  const query: ProductQuery = {
    omniclass,
    parameters
  }
  const request = {
    query,
    file_requests: fileRequests
  }
  const response = await fetch(apiEndpoints.generate_rfi_message, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  });

  let text = await response.text();

  // remove quotes in case of accidental leakage
  if (text.startsWith('"') && text.endsWith('"')) {
    text = text.slice(1, -1);
  }

  // replace \\n with \n
  text = text.replace(/\\n/g, '\n');

  return text;
}


export const createOmniclassTable = async (omniclass: OmniclassTableInfo): Promise<void> => {
  const response = await fetch(adminEndpoints.omniclass.create, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(omniclass)
  });

  await checkSuccess(response);
}


export const updateOmniclassData = async (omniclass: OmniclassTableInfo): Promise<void> => {
  const response = await fetch(adminEndpoints.omniclass.update, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(omniclass)
  });

  await checkSuccess(response);
}


export const createManufacturerVariant = async (omniclass: ManufacturerSpecificVariant): Promise<void> => {
  const response = await fetch(adminEndpoints.omniclass_variant.create, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(omniclass)
  });

  await checkSuccess(response);
}


export const updateManufacturerVariant = async (omniclass: ManufacturerSpecificVariant): Promise<void> => {
  const response = await fetch(adminEndpoints.omniclass_variant.update, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(omniclass)
  });

  await checkSuccess(response);
}


export const getOmniclassForAdmin = async (name: string): Promise<OmniclassTableInfo> => {
  const encodedName = encodeURIComponent(name)

  const response = await fetch(`${adminEndpoints.omniclass.get}?name=${encodedName}`);
  return await response.json();
}


export const getManufacturerVariantForAdmin = async (name: string, manufacturerTitle: string): Promise<ManufacturerSpecificVariant> => {
  const encodedName = encodeURIComponent(name);
  const encodedTitle = encodeURIComponent(manufacturerTitle);
  const response = await fetch(`${adminEndpoints.omniclass_variant.get}?name=${encodedName}&manufacturer=${encodedTitle}`);
  return await response.json();
}


/**
 * Get titles for manufacturer-specific variants for a given manufacturer
 *
 * @returns {Promise<string[]>} A list of manufacturer-specific variant titles belonging to the given manufacturer
 */
export const getOwnedManufacturerVariantTitles = async (manufacturerTitle: string): Promise<string[]> => {
  const encodedTitle = encodeURIComponent(manufacturerTitle);
  const response = await fetch(`${adminEndpoints.omniclass_variant.get_owned}?manufacturer=${encodedTitle}`);

  return await response.json();
}


/**
 * Get a record of all omniclass-manufacturer associations
 *
 * @returns {Promise<Record<string, Manufacturer[]>} A record with omniclass names as keys and manufacturer titles as values
 */
export const getAllOmniclassManufacturerAssociations = async (): Promise<Record<string, Manufacturer[]>> => {
  const response = await fetch(apiEndpoints.all_associations);
  return await response.json();
}