import {HTMLAttributes} from "react";
import {cn} from "@/lib/utils";

/**
 * This is a wrapper which limits the width of the content.
 *
 * This is used within the admin pages and the `ManufacturerDetails` pageThis is used within the admin pages and the `ManufacturerDetails` page.
 */
export const ContentWrapper = ({children, className}: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("flex flex-col gap-4 w-full md:max-w-[80vw] lg:max-w-[70vw] mx-auto pb-8", className)}>
    {children}
  </div>
)