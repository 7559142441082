import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { LinearProgress } from "@/components/LinearProgress";

import { SectionContainer, SectionHeader } from "@/app/components/Section";
import { SkeletonRows } from "@/app/components/SkeletonRows";
import { ManufacturerRow } from "./components/ManufacturerRow";
import { EmailRequestDialog } from "@/app/components/EmailRequestDialog";

import { SelectedParameter } from "@/types/Parameters";
import { RenderedManufacturer } from "@/types/Manufacturer";
import { SortMethod } from "@/types/SortMethod";

import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { Button } from "@/components/ui/Button";
import {ArrowsPointingInIcon, ArrowsPointingOutIcon} from "@heroicons/react/16/solid";


interface ManufacturerRowsProps {
  manufacturers: RenderedManufacturer[] | null;
  shouldHighlight: boolean;
  omniclass: string;
  selected: string[];
  onSelect: (manufacturer: string) => void;
  hasReachedMaxSelection: boolean;
}

interface ManufacturerListProps extends ManufacturerRowsProps {
  // controls whether a linear progress element is shown
  loading: boolean;
  selectedParameters: SelectedParameter[];
  omniclassName: string;
  changeSortMethod: (method: SortMethod) => void;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  showExpandButton: boolean;
}

const ManufacturerRows = ({ manufacturers, shouldHighlight, omniclass, selected, onSelect, hasReachedMaxSelection }: ManufacturerRowsProps) => {
  if (manufacturers === null) {
    return <SkeletonRows />
  } else if (manufacturers.length === 0) {
    return (
      <span className={"text-gray-500 text-sm p-4 text-center"}>
        No manufacturers found.
      </span>
    )
  } else {
    return (
      <ul className={"flex flex-col overflow-y-auto"}>
        {manufacturers.map((manufacturer, index) => (
          <ManufacturerRow manufacturer={manufacturer} shouldHighlight={shouldHighlight} omniclass={omniclass} key={index}
                           selected={selected.includes(manufacturer.title)}
                           onSelect={() => onSelect(manufacturer.title)}
                           disableSelection={hasReachedMaxSelection}
          />
        ))}
      </ul>
    )

  }

}

export const ManufacturerList = ({ manufacturers, loading, shouldHighlight, omniclass, selected, onSelect, hasReachedMaxSelection,
                                   selectedParameters, omniclassName, changeSortMethod, expanded, setExpanded, showExpandButton }: ManufacturerListProps) => {
  return (
    <SectionContainer className={"border-sky-500"}>
      <SectionHeader>
        <div className={"flex items-center"}>
          <div className={"flex items-center flex-grow"}>
            <div className={"flex-grow flex items-center"}>
              <span>
                Step 3: Review Manufacturers
              </span>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <QuestionMarkCircleIcon className={"h-5 w-5 ml-1 text-white"} />
                  </TooltipTrigger>
                  <TooltipContent className={"bg-white text-gray-800 font-semibold"}>
                    Selecting parameters and values will refine available manufacturers.
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>

            {showExpandButton && <div className={"flex items-center gap-1"}>
                <Button onClick={() => setExpanded(!expanded)} className={"text-sm flex items-center gap-1 -my-2"} size={"sm"} variant={"secondary"}>
                  {expanded ? <ArrowsPointingInIcon className={"h-4 w-4"} /> : <ArrowsPointingOutIcon className={"h-4 w-4"} /> }
                  {expanded ? "Collapse" : "Expand"}
                </Button>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <QuestionMarkCircleIcon className={"h-5 w-5 text-white"} />
                    </TooltipTrigger>
                    <TooltipContent className={"bg-white text-gray-800 font-semibold"}>
                      Expanding the manufacturer list shows manufacturers for the parent omniclass.
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
            </div>}
          </div>
        </div>
      </SectionHeader>

      {/* Control / Input Section */}
      <div className={"flex gap-2 items-center py-2 mx-4"}>

        {/* "Sort by" controls */}
        <div className={"flex items-center gap-2 flex-grow"}>
          <span className={"text-nowrap text-sm text-gray-600"}>Sort by:</span>
          <Select defaultValue={"relevancy"} onValueChange={changeSortMethod}>
            <SelectTrigger className={"text-sm w-fit"}>
              <SelectValue placeholder={"Relevancy"} className={"text-sm"}/>
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel className={"text-sm"}>Sorting Options:</SelectLabel>
                <SelectItem value="relevancy" className={"text-sm"}>Relevancy</SelectItem>
                <SelectItem value="alphabetically" className={"text-sm"}>Alphabetically</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <EmailRequestDialog
          manufacturers={selected}
          deselectManufacturer={onSelect}
          omniclassName={omniclassName}
          selectedParameters={selectedParameters}
        />

      </div>

      <div className={`flex flex-col overflow-y-auto ${loading ? '' : 'mt-0.5'}`}>

        {loading && <LinearProgress />}

        <ManufacturerRows
          manufacturers={manufacturers}
          shouldHighlight={shouldHighlight}
          omniclass={omniclass}
          selected={selected}
          onSelect={onSelect}
          hasReachedMaxSelection={hasReachedMaxSelection}
        />

      </div>
    </SectionContainer>
  )
}