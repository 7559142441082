import {AggregatorContent, AnnotatedManufacturer, blankAggregatorContent} from "@/types/Manufacturer";

/**
 * Overlay the manufacturer's links for professionals with the aggregator content.
 *
 * If the manufacturer has a link to a content aggregator site, but the aggregator content object does not reflect this,
 * then the corresponding key in the aggregator content object is set to -1. This influences the UI to display text instead of showing
 * the count.
 *
 * @param manufacturer - The manufacturer to overlay the links with
 */
export const overlayLinksWithAggregatorContent = (manufacturer: AnnotatedManufacturer): AggregatorContent => {
  // If the manufacturer has no links for professionals, return the aggregator content or a blank aggregator content object
  if (!manufacturer.links_for_professionals) return manufacturer.aggregator_content || blankAggregatorContent;
  // If the manufacturer has links for professionals, check if any of the links contain the aggregator content
  // and update the aggregator content object accordingly
  else if (manufacturer.links_for_professionals.length > 0) {
    const aggregatorContent = manufacturer.aggregator_content || blankAggregatorContent;
    let updatedAggregatorContent = {...aggregatorContent};

    if (!aggregatorContent.architizer && manufacturer.links_for_professionals.some(link => link.title.toLowerCase().includes("architizer"))) {
      updatedAggregatorContent = {...updatedAggregatorContent, architizer: -1};
    }

    if (!aggregatorContent.bimobject && manufacturer.links_for_professionals.some(link => link.title.toLowerCase().includes("bimobject"))) {
      updatedAggregatorContent = {...updatedAggregatorContent, bimobject: -1};
    }

    if (!aggregatorContent.bimsmith && manufacturer.links_for_professionals.some(link => link.title.toLowerCase().includes("bimsmith"))) {
      updatedAggregatorContent = {...updatedAggregatorContent, bimsmith: -1};
    }

    if (!aggregatorContent.caddetails && manufacturer.links_for_professionals.some(link => link.title.toLowerCase().includes("caddetails"))) {
      updatedAggregatorContent = {...updatedAggregatorContent, caddetails: -1};
    }

    return updatedAggregatorContent;
  } else {
    return manufacturer.aggregator_content || blankAggregatorContent;
  }
}