import {Parameter, SelectedParameter} from "@/types/Parameters";
import {
  highlightedColor,
  hoveredColor,
  SectionContainer,
  SectionHeader,
  SectionItem
} from "./Section";
import {Input} from "@/components/ui/Input";
import {useEffect, useRef, useState} from "react";
import { Checkbox } from "@/components/ui/checkbox";
import {Button} from "@/components/ui/Button";
import {XMarkIcon} from "@heroicons/react/16/solid";
import {Label} from "@/components/ui/label";


interface ValueItemProps {
  value: string;
  isSelected: boolean;
  handleSelectValue: () => void;
}

export const ValueItem = ({value, isSelected, handleSelectValue}: ValueItemProps) => {
  const bg_class = isSelected ? highlightedColor : hoveredColor;
  return (
    <SectionItem onClick={handleSelectValue}
        className={bg_class}>
      {value}
    </SectionItem>
  )
}

interface ValueSectionProps {
  parameter?: Parameter;
  selectedValues: SelectedParameter[];
  handleSelectValue: (parameterName: string, value: string) => void;
}

export const ValueSection = ({parameter, selectedValues, handleSelectValue}: ValueSectionProps) => {
  const [userInput, setUserInput] = useState<string>("");
  // iterate through the selected values and the parameter values to determine if the `SelectedParameter` is not a member of the `Parameter` values
  const [userSelected, setUserSelected] = useState<boolean>(false);
  const [resetFlag, setResetFlag] = useState<boolean>(false)

  const inputElementRef = useRef<HTMLInputElement>(null);

  /**
   * Reset or set the user input
   *
   * When loading a parameter, uf the selected value is not found in the `Parameter` values, then it is assumed that it is
   * custom user input. Then the `input` element is set to the selected value, and the `userSelected` state is set to `true`.
   * Otherwise, the `input` element is reset to an empty string, and the `userSelected` state is set to `false`.
   *
   * The presence of a value within `selectedValues` which does not correspond to a possible value in the `Parameter` values
   * indicates that the user has entered a custom value.
   */
  useEffect(() => {
    if (parameter !== undefined) {
      const current_parameter_value = selectedValues.find(_parameter => _parameter.name === parameter.name)?.value;

      if (current_parameter_value === undefined || current_parameter_value === "" || parameter.values.includes(current_parameter_value)) {
        setUserInput("");
        setUserSelected(false);
      } else {
        setUserInput(current_parameter_value);
        setUserSelected(true);
      }
    }
  }, [parameter])

  /**
   * Handles interactions with `ValueItem` components
   *
   * This calls the `handleSelectValue` function and sets the `userSelected` state to `false`. Since `handleSelectValue`
   * handles both selection and deselection of the parameter-values, it is always called.
   *
   * @param value - value string which is being interacted with
   */
  const handleGeneratedValueSelect = (value: string) => {
    if (parameter) {
      handleSelectValue(parameter.name, value);
      setUserSelected(false);
    }
  }

  /**
   * Handles checkbox interactions
   *
   * When the user is when going from unchecked to checked, the `handleSelectValue` function is called with the custom
   * user input. Otherwise, the checkbox is unchecked.
   */
  const handleUserInputClick = () => {
    if (parameter && userInput !== "" && !userSelected) {
      handleSelectValue(parameter.name, userInput);
      setUserSelected(true);
    } else if (userSelected) {
      setUserSelected(false);
    }
  }

  const resetUserInput = () => {
    setUserInput("");
    setUserSelected(false);
    // set the reset flag to true to focus the input element
    setResetFlag(true);
    handleSelectValue(parameter?.name || "", "");
  }

  /**
   * Focus the input after userSelected is updated, but only after the reset button has been pressed
   *
   * This is necessary because the input element is disabled by `userSelected` and the `resetFlag` is used to
   * focus the input element after the states have been updated.
   */
  useEffect(() => {
    if (!userSelected && resetFlag && inputElementRef.current) {
      inputElementRef.current.focus();
      // Reset the flag after focusing
      setResetFlag(false);
    }
  }, [userSelected, resetFlag]);


  return (
    <SectionContainer>
      <SectionHeader>Step 2: Select Available Values</SectionHeader>
      <ul className={"flex flex-col overflow-y-auto max-h-full"}>

        {/* Shown when no parameter is selected */}
        {parameter === undefined && <p className={"px-4 py-2 text-slate-500 mt-2"}>Select a parameter name to see available values.</p>}

        {/* Custom user input */}
        {parameter !== undefined && <SectionItem className={`flex items-center gap-2 ${userSelected ? highlightedColor : ''}`}>
          <Checkbox
            checked={userSelected}
            onCheckedChange={handleUserInputClick}
            disabled={userInput.length === 0}
            data-testid={"custom-value-checkbox"}
          />
          <Label className={"sr-only"} htmlFor={"custom-parameter-value"}>Custom Value</Label>
          <Input
            type={"text"}
            id={"custom-parameter-value"}
            name={"custom-parameter-value"}
            placeholder={"Custom Value..."}
            className={"w-full text-sm bg-white"}
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleUserInputClick();
              }
            }}
            data-testid={"custom-value-input"}
            disabled={userSelected}
            ref={inputElementRef}
          />
          {userInput && userSelected &&
          <Button
            onClick={resetUserInput}
            variant={"destructive"}
            size={"icon"}>
            <XMarkIcon className={"h4 w-4"} />
          </Button>}
        </SectionItem>}

        {/* Parameter values */}
        {parameter?.values.map((value, index) => {
          const isSelected = value !== "" && selectedValues.find(_parameter => _parameter.name === parameter?.name)?.value === value;
          return <ValueItem key={index}
                            value={value}
                            isSelected={isSelected}
                            handleSelectValue={() => handleGeneratedValueSelect(value)} />
        })}
      </ul>
    </SectionContainer>
  )
}