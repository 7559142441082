import {Navigate} from "react-router-dom"
import {useSession} from "@/hooks/useSession";
import {SUPABASE} from "@/lib/db";
import {ReactElement} from "react";

export const RequireToken = ({children}: { children: ReactElement }) => {
  const { session, loading } = useSession();

  if (loading) {
    return <></>
  } else if (!session) {

    console.warn(`User is not logged in, redirecting from ${window.location} to login page`);
    return <Navigate to='/login' replace/>;
  } else {
    return children;
  }
}

export const Logout = () => {
  SUPABASE.auth.signOut().then(() => {})

  return <Navigate to='/login' replace />;
}